import React, { useEffect } from "react";
import Scheduler, { View } from "devextreme-react/scheduler";
import Form, { Item, ButtonItem, GroupItem } from "devextreme-react/form";
import SelectBox from "devextreme-react/select-box";
import "./clientManagement.scss";
import { useAuth } from "../../contexts/auth";
import {
  fetchThisClientData,
  getClients,
  updateClient,
  updateClientx,
  updateCurrentUser,
  UpdateCFIncomeData,
  updateCurrentDefaultClient,
} from "./clientManagementData";
import { Button } from "devextreme-react/button";
import ExcelForm from "./clientBankAccounts/excelForm/excelForm";
import ExcelTaxForm from "./clientBankAccounts/excelTaxForm/excelTaxForm";
import ClientBankAccounts from "./clientBankAccounts/clientBankAccounts";
import DebtSummary from "./clientBankAccounts/debtSummary";
import ProgressSummary from "./clientBankAccounts/progressSummary";
import NetWorth from "./clientBankAccounts/netWorth";
import ClientTransactions from "./clientBankAccounts/clientTransactions";
import Interest from "./clientBankAccounts/interest";
import ClientInvestments from "./clientBankAccounts/clientInvestments";
import ClientAssets from "./clientBankAccounts/clientAssets";
import CustomerProfile from "./clientBankAccounts/customerProfile";
import Transfers from "./clientBankAccounts/transfers";
import ClientTransactionsSummary from "./clientBankAccounts/clientTransactionsSummary";
import { GenerateExcelFiles } from "./clientBankAccounts/generateExcelFiles";
import { GeneratePresentation } from "./clientBankAccounts/generatePresentation";
import { GenerateClientPresentation } from "./clientBankAccounts/generateClientPresentation";
import { GenerateCarryingCharges } from "./clientBankAccounts/generateCarryingCharges";

import ImportTransactions from "./clientBankAccounts/importTransactions";
import ImportBankCSVImport from "./clientBankAccounts/ImportbankCSVImport";

import ClientOwners from "./clientOwners";

import CFCashFlow from "./clientBankAccounts/cfCashFlow.js";
import CFGroupPlans from "./clientBankAccounts/cfGroupPlans";
import CFStructure from "./clientBankAccounts/cfStructure";
import CombinedComponent from "./clientBankAccounts/combinedComponent";
import CFTax from "./clientBankAccounts/cfTax";
import CFCopyStructure from "./clientBankAccounts/cfCopyStructure";
import CFEnterStructure from "./clientBankAccounts/cfEnterStructure";

//import GetStockPrice from "./clientBankAccounts/getStockprice";
import ImportExcel from "./clientBankAccounts/importExcel";
import DateBox from "devextreme-react/date-box";
//>>import "./clientManagement.css";
import { set } from "date-fns";
import PresentationPage from "./clientBankAccounts/presentationpage";
//import { set } from "date-fns";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AccountStatement from "./clientBankAccounts/accountStatement";
//comment
const MySwal = withReactContent(Swal);
const MySwal2 = withReactContent(Swal);

const clients = ["sam", "lou"];

const ClientManagement = () => {
  const [sharedValue, setSharedValue] = React.useState(0);

  const { user, updateUser } = useAuth();

  const [currentClientCode, setCurrentClientCode] = React.useState(
    user.thisClientCode
  );

  const [thisWidth, setThisWidth] = React.useState("100%");

  const [key, setKey] = React.useState(Math.random());
  //const [customerData, setCustomerData] = React.useState(clients); // this is the aray of customers
  const [customerList, setCustomerList] = React.useState([]); // this is the aray of customers
  const [customerName, setCustomerName] = React.useState("");
  const [startdate, setStartDate] = React.useState("");
  const [enddate, setEndDate] = React.useState("");
  const [processDates, setProcessDates] = React.useState("");

  const [showBanks, setShowBanks] = React.useState(false);
  const [showBanksForm, setShowBanksForm] = React.useState(false);
  const [showInfo, setShowInfo] = React.useState(false);
  const [ShowSummary, setShowSummary] = React.useState(false);

  const [showDashboard, setShowDashboard] = React.useState(false);
  const [showOwners, setShowOwners] = React.useState(false);
  const [showAssets, setShowAssets] = React.useState(false);
  const [showInvestments, setShowInvestments] = React.useState(false);

  const [showTransfers, setShowTransfers] = React.useState(false);
  const [showInterest, setShowInterest] = React.useState(false);

  const [showDebtSummary, setFormDebtSummary] = React.useState(false);
  const [showDebtSummary2, setFormDebtSummary2] = React.useState(false);

  const [showProgress, setFormProgress] = React.useState(false);
  const [showNetWorth, setFormNetWorth] = React.useState(false);

  const [ShowTransactions, setShowTransactions] = React.useState(false);
  const [ShowImport, setShowImport] = React.useState(false);
  const [ShowImportExcel, setShowImportExcel] = React.useState(false);
  const [ShowProcessImport, setShowProcessImport] = React.useState(false);
  const [ShowCreateExcel, setShowCreateExcel] = React.useState(false);
  const [ShowCreatePresentation, setShowCreatePresentation] =
    React.useState(false);
  const [ShowCreateClientPresentation, setShowClientCreatePresentation] =
    React.useState(false);

  const [ShowCashFlow, setShowCashFlow] = React.useState(false);
  const [ShowCashFlowInput, setShowCashFlowInputValue] = React.useState(false);
  const [ShowIncomeForms, setShowIncomeForms] = React.useState(false);
  const [ShowGroupPlans, setShowGroupPlans] = React.useState(false);

  const [showPrior, setPrior] = React.useState(true);

  const [startdatex, setStartdatex] = React.useState(null);
  const [enddatex, setEnddatex] = React.useState(null);
  const [refreshKeyx, setRefreshKey] = React.useState(0); // for dates to refresh grid when date changed
  const [resetflag, setResetFlag] = React.useState(false);
  const [EditExcelOn, setEditExcelOn] = React.useState(false);
  const [PresentationOn, setPresentationOn] = React.useState(false);
  const [ClientPresentationOn, setClientPresentationOn] = React.useState(false);

  const [CopyStructure, setStructure] = React.useState(false);

  const [EnterStructure, setEnterStructure] = React.useState(false);
  const [EnterTaxStructure, setEnterTaxStructure] = React.useState(false);

  const [presentationDate, setPresentationDate] = React.useState("");

  const [createCarryingCharges, setCreateCarryingCharges] =
    React.useState(false);
  const [showAccountStatement, setShowAccountStatement] = React.useState(false);

  const [clientPassword, setClientPassword] = React.useState("");

  const [ShowStructure, setShowStructure] = React.useState(false);

  const handleStartDateChangex = (e) => {
    if (
      currentClientCode === null ||
      currentClientCode === undefined ||
      currentClientCode === "" ||
      e.value === null ||
      e.value === undefined ||
      e.value === "" ||
      enddatex.value === 0
    )
      return;
    const newStartDate = e.value; // Capture the new start date value from the event
    setStartdatex(newStartDate); // Schedule the state update
    //console.log("start date: ", newStartDate, "end date: ", enddatex);

    updateClientx(currentClientCode, newStartDate); // Use newStartDate directly
    resetflag ? setResetFlag(false) : setResetFlag(true);
  };

  const handleMappingUpdated2 = (value) => {
    setEditExcelOn(value);
    setShowCreateExcel(false);
  };
  const handleMappingUpdated3 = (value) => {
    setPresentationOn(value);
    setShowCreatePresentation(false);
  };
  const handleMappingUpdated4 = (value) => {
    //setClientPresentationOn(value);
    //setShowClientCreatePresentation(false);
    //this.setState({ EditExcelOn: false });
  };

  const handleIncomeUpdated = (value) => {
    setShowIncomeForms(value);
  };

  const handleEndDateChangex = (e) => {
    if (
      currentClientCode === null ||
      currentClientCode === undefined ||
      currentClientCode === "" ||
      e.value === null ||
      e.value === undefined ||
      e.value === "" ||
      enddatex.value === 0
    )
      return;
    const newEndDate = e.value; // Capture the new start date value from the event
    setEnddatex(newEndDate); // Schedule the state update
    //console.log("end date: ", newEndDate, "start date: ", startdatex);
    //console.log("start date: ", newEndDate);

    updateClientx(currentClientCode, "", newEndDate); // Use newStartDate directly
    resetflag ? setResetFlag(false) : setResetFlag(true);
  };

  //////
  // this SHOULD set the current client code to the last client code used by the user
  //////

  useEffect(() => {
    (async () => {
      const resultCustomerdata = await getClients();

      setCustomerList(resultCustomerdata.data);

      const resultCustomerData = await getClients();
      if (resultCustomerData && resultCustomerData.data) {
        // Assuming resultCustomerData.data is an array of customers
        // and each customer object has a 'disabled' property
        const filteredCustomers = resultCustomerData.data.filter(
          (customer) => !customer.inactive
        );
        setCustomerList(filteredCustomers);
      }

      setKey(resultCustomerdata.data.key);
      setThisWidth("80%");
      //setCurrentClientCode(user.lastClientUpdated);
      setCurrentClientCode(user.lastClientUpdated);
      // console.log(
      //   "inside initial call user values ",
      //   user,
      //   "and last",
      //   user.lastClientUpdated,
      //   "and client: ",
      //   currentClientCode
      // );
    })();
    return () => {};
  }, []);

  ////
  // this routine is CALLED when a new customer is selected
  //
  const setClientData = async (e) => {
    if (e.value === null || e.value === undefined || e.value === "") return;
    if (e.value === null || e.value === undefined || e.value === "") return;
    setSharedValue((prevKey) => prevKey + 1);
    setCurrentClientCode(e.value);
    updateUser(user.lastClientUpdated, e.value);
    //console.log("user stuff:", user);
    updateCurrentDefaultClient(user.UserCode, user.lastClientUpdated);
    // console.log(
    //   "value of e ",
    //   e.value,
    //   "user.lastClientUpdated",
    //   user.lastClientUpdated
    // ); //updateUser({ lastClientUpdated: e.value });
  };

  useEffect(
    () => {
      // if (
      //   currentClientCode === null ||
      //   currentClientCode === undefined ||
      //   currentClientCode === ""
      // )
      //   return;
      (async () => {
        //      console.log("current client code: ", currentClientCode);
        const result = await fetchThisClientData(currentClientCode);
        //console.log("passsed back", result);
        // setCustomerData({
        //   ClientCode: result.CLIENTCODE,
        //   Name: result.NAME,
        //   AddressLineOne: result.ADDRESSLINEONE,
        //   AddressLineTwo: result.ADDRESSLINETWO,
        //   AddressLineThree: result.ADDRESSLINETHREE,
        //   AddressLineFour: result.ADDRESSLINEFOUR,
        //   Country: result.COUNTRY,
        //   PostalZip: result.POSTALZIP,
        //   UniqueID: result.UNIQUEID,
        //   StartDate: result.STARTDATE,
        //   EndDate: result.ENDDATE,
        // });

        //const resultCustomerdata = await getClients();
        //setCustomerList(resultCustomerdata.data);
        //setKey(resultCustomerdata.data.key);
        //setThisWidth("70%");
        //console.log("results:", result);

        updateUser({ thisClientcode: result.CLIENTCODE });
        updateUser({ lastClientUpdated: result.CLIENTCODE });
        setClientPassword(result.CLIENTPASSWORD);
        setCustomerName(result.NAME);
        setStartDate(result.STARTDATE);
        setEndDate(result.ENDDATE);
        setStartdatex(result.STARTDATE);
        setEnddatex(result.ENDDATE);
        updateCurrentUser(user.UserCode, currentClientCode);
        setPresentationDate(result.PRESENTATIONDATE);
        setallflags();
        //console.log("start datex : ", startdate, " end datex : ", enddate);

        //if (startdate !== null && startdate !== undefined && startdate !== "") {
        // setProcessDates(
        //   "Currently Processing from " +
        //     result.STARTDATE +
        //     " to " +
        //     result.ENDDATE
        // );
      })();

      return () => {};
    },
    [currentClientCode],
    resetflag
  );

  useEffect(() => {
    const promptPassword = async () => {
      if (clientPassword) {
        let enteredPassword = "";
        let isCanceled = false;

        while (enteredPassword !== clientPassword && !isCanceled) {
          const { value, isDismissed } = await MySwal.fire({
            title: "Enter Password",
            input: "password",
            inputLabel: "Please enter the client password",
            inputPlaceholder: "Enter password",
            inputAttributes: {
              autocapitalize: "off",
            },
            showCancelButton: true, // Enable the cancel button
            confirmButtonText: "Submit",
            cancelButtonText: "Cancel",
            allowOutsideClick: false,
          });

          if (isDismissed) {
            // Handle cancel action
            isCanceled = true;
          } else {
            enteredPassword = value;

            if (enteredPassword !== clientPassword) {
              await MySwal.fire({
                icon: "error",
                title: "Incorrect Password",
                text: "Please try again.",
              });
            }
          }
        }

        if (isCanceled) {
          // Blank out the client code on cancel
          setCurrentClientCode("");
          setCustomerName("");
          setClientPassword(""); // Reset the password
          //console.log("Password prompt canceled. Client code reset.");
        }
      }
    };

    promptPassword();
  }, [clientPassword]);

  const setallflags = () => {
    setShowInfo(false);
    setShowOwners(false);
    setShowBanks(false);
    setShowAssets(false);
    setShowInvestments(false);
    setShowDashboard(false);
    setFormDebtSummary(false);
    setFormDebtSummary2(false);
    setShowTransactions(false);
    setShowImport(false);
    setShowImportExcel(false);
    setShowProcessImport(false);
    setShowBanksForm(false);
    setShowInterest(false);
    setShowTransfers(false);
    setFormProgress(false);
    setFormNetWorth(false);
    setShowCreateExcel(false);

    setShowCashFlow(false);
    setShowIncomeForms(false);
    setShowGroupPlans(false);
    setShowCashFlowInputValue(false);
    setShowSummary(false);
    setStructure(false);
    setEnterStructure(false);
    setEnterTaxStructure(false);
    setCreateCarryingCharges(false);
    setShowAccountStatement(false);
    setShowStructure(false);
    //setShowClientCreatePresentation(false);
  };

  const setForm1 = () => {
    setallflags();
    setShowInfo(true);
    setShowOwners(true);
  };
  const setForm2 = () => {
    setallflags();
    setShowBanks(true);
    setShowBanksForm(true);
  };
  // const setForm3 = () => {
  //   setallflags();
  //   setShowOwners(true);
  // };
  const setForm4 = () => {
    setallflags();
    setShowAssets(true);
  };
  const setForm5 = () => {
    setallflags();
    setShowInvestments(true);
  };
  const setForm6 = () => {
    setallflags();
    setShowDashboard(true);
  };
  const setForm7 = () => {
    setallflags();
    setShowCashFlow(true);
  };

  const setShowProgress = () => {
    setallflags();
    setShowDashboard(true);
    setFormProgress(true);
  };
  const setShowDebtSummaryMain = () => {
    setallflags();
    setShowDashboard(true);
    setFormDebtSummary2(true);
  };

  const setStructureMain = () => {
    setallflags();
    setShowDashboard(true);
    setShowGroupPlans(true);
  };

  const setShowDebtSummary = () => {
    setallflags();
    setShowDashboard(true);
    setFormDebtSummary(true);
  };
  const setShowNetWorth = () => {
    setallflags();
    setShowDashboard(true);
    setFormNetWorth(true);
  };

  const setFormTransactions = () => {
    setallflags();
    setShowBanks(true);
    setShowBanksForm(false);
    setShowTransactions(true);
  };
  const setFormSummary = () => {
    setallflags();
    setShowBanks(false);
    setShowBanksForm(false);
    setShowSummary(true);
  };
  const setFormImport = () => {
    setallflags();
    setShowBanks(true);
    setShowBanksForm(false);
    setShowImport(true);
    setShowProcessImport(false);
  };
  const setFormImportExcel = () => {
    setallflags();
    setShowBanks(true);
    setShowBanksForm(false);
    setShowImport(false);
    setShowImportExcel(true);
    setShowProcessImport(false);
  };
  // const setFormProcessImports = () => {
  //   setallflags();
  //   setShowBanks(true);
  //   setShowBanksForm(false);
  //   setShowProcessImport(true);
  // };
  const setFormInterest = () => {
    setallflags();
    setShowBanks(true);
    setShowBanksForm(false);
    setShowInterest(true);
  };
  const setFormTransfers = () => {
    setallflags();
    setShowBanks(true);
    setShowTransfers(true);
  };
  const setFormCreateExcel = () => {
    setallflags();
    setShowDashboard(true);
    setShowCreateExcel(true);
  };

  const setFormCreatePresentation = () => {
    setallflags();
    setShowDashboard(true);
    setShowCreatePresentation(true);
  };
  const setFormCreateClientPresentation = () => {
    setallflags();
    setShowDashboard(true);
    setShowClientCreatePresentation(true);
  };
  const setShowIncome = () => {
    setallflags();
    setShowCashFlow(true);
    setShowIncomeForms(true);
  };

  const setShowStructureForms = () => {
    setallflags();
    setShowCashFlow(true);
    setShowStructure(true);
  };
  const setShowCashFlowInput = () => {
    setallflags();
    setShowCashFlow(true);
    setShowCashFlowInputValue(true);
  };
  const setShowGroupPlansInput = () => {
    setallflags();
    setShowCashFlow(true);
    setShowGroupPlans(true);
  };
  const setShowCopyStructure = () => {
    setallflags();
    setShowCashFlow(true);
    setStructure(true);
  };
  const setShowEnterStructure = () => {
    setallflags();
    setShowCashFlow(true);
    setEnterStructure(true);
  };

  const setShowEnterTaxStructure = () => {
    setallflags();
    setShowCashFlow(true);
    setEnterTaxStructure(true);
  };

  const setShowCreateCarryingButton = () => {
    setallflags();
    setCreateCarryingCharges(true);
  };

  const closeForm = () => {
    setStructure(false);
  };

  const handleClientPresentationClick = () => {
    setShowClientCreatePresentation(true);

    // Fullscreen API logic
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.mozRequestFullScreen) {
      /* Firefox */
      document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullscreen) {
      /* Chrome, Safari & Opera */
      document.documentElement.webkitRequestFullscreen();
    } else if (document.documentElement.msRequestFullscreen) {
      /* IE/Edge */
      document.documentElement.msRequestFullscreen();
    }
  };

  const updatePostingValues = async () => {
    try {
      const response = await UpdateCFIncomeData(
        currentClientCode,
        presentationDate
      );
      //console.log("response", response);
      if (response.successmessage) {
        MySwal.fire({
          icon: "success",
          title: "Success",
          text: response.successmessage,
        });
      } else {
        MySwal.fire({
          icon: "error",
          title: "Error",
          text: response.returnerrormessage,
        });
      }
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while updating the values.",
      });
    }
  };

  const setFormAccountStatement = () => {
    setallflags();
    setShowBanks(true);
    setShowAccountStatement(true);
  };

  return (
    <>
      <div className="responsive-paddingsx my-top-section">
        {ShowCreateClientPresentation !== true && (
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p style={{ marginRight: "10px", marginBottom: "0" }}>Client:</p>
              <SelectBox
                className="white-text-selectbox"
                style={{ width: "200px", height: "40px", marginRight: "10px" }} // Adjusted to include marginRight
                items={customerList}
                valueExpr="label"
                displayExpr="label"
                value={currentClientCode}
                searchEnabled={true}
                onValueChanged={setClientData}
              />
              <p style={{ margin: "0 150px 0 0" }}>{customerName}</p>{" "}
              {/* Adjusted margins for spacing */}
              <h6 style={{ margin: "0 15px 0 0" }}>Processing Dates</h6>{" "}
              {/* Adjusted margins for spacing */}
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="date-picker" style={{ marginRight: "10px" }}>
                  {" "}
                  {/* Added marginRight for spacing */}
                  <label>
                    Start Date (MM/DD/YYYY):
                    <DateBox
                      type="date"
                      value={startdatex}
                      onValueChanged={handleStartDateChangex}
                    />
                  </label>
                </div>
                <div className="date-picker">
                  <label>
                    End Date (MM/DD/YYYY):
                    <DateBox
                      type="date"
                      value={enddatex}
                      onValueChanged={handleEndDateChangex}
                    />
                  </label>
                </div>
              </div>
              <label>
                Client Presentation:
                <div>
                  <input
                    style={{
                      width: "50px",
                      height: "20px",
                      marginTop: "5px",
                      marginRight: "5px",
                    }}
                    type="checkbox"
                    value={ShowCreateClientPresentation}
                    onClick={handleClientPresentationClick}
                    onChange={(e) =>
                      setShowClientCreatePresentation(e.target.checked)
                    }
                  />
                </div>
              </label>
            </div>
          </>
        )}
      </div>

      <div>
        <div className="app2 " style={{ display: "flex", alignItems: "left" }}>
          <div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(6, auto)",
                gap: "10px",
              }}
            >
              {ShowCreateClientPresentation !== true && (
                <>
                  <Button text="Client Info" onClick={setForm1} />
                  <Button text="Bank Accounts" onClick={setForm2} />
                  <Button text="Assets" onClick={setForm4} />
                  <Button text="Investments" onClick={setForm5} />
                  <Button text="Dashboard" onClick={setForm6} />
                  <Button text="Income" onClick={setForm7} />
                </>
              )}
              {showBanks && (
                <>
                  <Button text="Import Excel" onClick={setFormImportExcel} />
                  <Button text="Import / Process" onClick={setFormImport} />
                  <Button text="Transfers" onClick={setFormTransfers} />
                  <Button text="Interest" onClick={setFormInterest} />
                  <Button text="Transactions" onClick={setFormTransactions} />
                  <Button text="Summary" onClick={setFormSummary} />
                  <Button
                    text="Account Statement"
                    onClick={setFormAccountStatement}
                  />

                  {/* Empty divs for alignment */}
                </>
              )}
              {(showDashboard || ShowCreateClientPresentation) && (
                <>
                  <Button text="Net Worth" onClick={setShowNetWorth} />
                  <Button text="Progress" onClick={setShowProgress} />

                  {ShowCreateClientPresentation !== true && (
                    <>
                      <Button
                        text="Debt Summary"
                        onClick={setShowDebtSummaryMain}
                      />
                      <Button text="Structure " onClick={setStructureMain} />
                      <Button
                        text="Create Excel"
                        onClick={setFormCreateExcel}
                      />
                      <Button
                        text="Create Presentation"
                        onClick={setFormCreatePresentation}
                      />
                      <Button
                        text="Create Carrying Charges"
                        onClick={setShowCreateCarryingButton}
                      />
                    </>
                  )}
                  {ShowCreateClientPresentation !== true && (
                    <>
                      <div></div>
                      <div></div>
                      <div></div>
                    </>
                  )}
                </>
              )}
              {(ShowCashFlow || ShowCreateClientPresentation) && (
                <>
                  <Button text="Cash Flow" onClick={setShowIncome} />
                  <Button text="Tax" onClick={setShowCashFlowInput} />
                  <Button
                    text="Structure"
                    onClick={setShowStructureForms}
                    //nClick={setShowGroupPlansInput}
                  />
                  <Button text="Debt Summary" onClick={setShowDebtSummary} />
                  {ShowCreateClientPresentation !== true && (
                    <>
                      {/* <Button
                        text="Copy Structure"
                        onClick={setShowCopyStructure}
                      /> */}
                      <Button
                        text="Enter Excel Data"
                        onClick={setShowEnterStructure}
                      />
                      <Button
                        text="Enter Excel Tax Data"
                        onClick={setShowEnterTaxStructure}
                      />

                      <Button
                        text={`Post using ${presentationDate}`}
                        onClick={updatePostingValues}
                      />
                    </>
                  )}

                  {/* Empty divs for alignment */}
                  <div></div>
                  <div></div>
                  <div></div>
                </>
              )}
            </div>
          </div>
        </div>

        {showInfo && (
          <>
            <CustomerProfile clientCode={currentClientCode} />
            <ClientOwners clientCode={currentClientCode} />
          </>
        )}
      </div>
      {showBanksForm && (
        <>
          <p></p>
          <ClientBankAccounts clientCode={currentClientCode} />
        </>
      )}
      {showDebtSummary && (
        <>
          <p></p>
          <DebtSummary
            clientCode={currentClientCode}
            thisWidth={thisWidth}
            showPrior={showPrior}
            ShowCreateClientPresentation={ShowCreateClientPresentation}
          />
        </>
      )}
      {showDebtSummary2 && (
        <>
          <p></p>
          <DebtSummary
            clientCode={currentClientCode}
            thisWidth={thisWidth}
            showPrior={showPrior}
            ShowCreateClientPresentation={ShowCreateClientPresentation}
          />
        </>
      )}
      {showProgress && (
        <>
          <p></p>
          <ProgressSummary
            clientCode={currentClientCode}
            thisWidth={thisWidth}
            showPrior={showPrior}
            ShowCreateClientPresentation={ShowCreateClientPresentation}
          />
        </>
      )}
      {showNetWorth && (
        <>
          <p></p>
          <NetWorth
            clientCode={currentClientCode}
            thisWidth={thisWidth}
            showPrior={showPrior}
            ShowCreateClientPresentation={ShowCreateClientPresentation}
          />
        </>
      )}

      {ShowTransactions && (
        <>
          <p></p>
          <ClientTransactions clientCode={currentClientCode} />
        </>
      )}
      {ShowSummary && (
        <>
          <p></p>
          <ClientTransactionsSummary clientCode={currentClientCode} />
        </>
      )}
      {ShowImport && (
        <>
          <ImportBankCSVImport
            clientCode={currentClientCode}
            sharedValue={sharedValue}
            onValueChange={() => setSharedValue((prev) => prev + 1)} // Increment sharedValue
          />
          <ImportTransactions
            clientCode={currentClientCode}
            sharedValue={sharedValue}
          />
        </>
      )}
      {ShowImportExcel && (
        <ImportExcel clientCode={currentClientCode} sharedValue={sharedValue} />
      )}
      {showInterest && (
        <Interest clientCode={currentClientCode} sharedValue={sharedValue} />
      )}
      {showTransfers && (
        <Transfers clientCode={currentClientCode} sharedValue={sharedValue} />
      )}
      {showAssets && (
        <ClientAssets
          clientCode={currentClientCode}
          sharedValue={sharedValue}
        />
      )}
      {showInvestments && (
        <ClientInvestments
          clientCode={currentClientCode}
          sharedValue={sharedValue}
        />
      )}
      {ShowCreateExcel === true && (
        <div>
          <GenerateExcelFiles
            clientCode={currentClientCode}
            onMappingUpdated2={handleMappingUpdated2}
          />
        </div>
      )}
      {createCarryingCharges === true && (
        <div>
          <GenerateCarryingCharges
            clientCode={currentClientCode}
            onMappingUpdated2={handleMappingUpdated2}
          />
        </div>
      )}
      {ShowCreatePresentation === true && (
        <div>
          <GeneratePresentation
            clientCode={currentClientCode}
            onMappingUpdated3={handleMappingUpdated3}
          />
        </div>
      )}
      {/* {ShowCreateClientPresentation === true && (
        <div>
          <GenerateClientPresentation
            clientCode={currentClientCode}
            onMappingUpdated4={handleMappingUpdated4}
          />
        </div>
      )} */}
      {ShowIncomeForms === true && (
        <div>
          <CFCashFlow
            clientCode={currentClientCode}
            ShowCreateClientPresentation={ShowCreateClientPresentation}

            //onMappingUpdated2={handleIncomeUpdated}
          />
        </div>
      )}
      {ShowStructure === true && (
        <div>
          <CFStructure
            clientCode={currentClientCode}
            ShowCreateClientPresentation={ShowCreateClientPresentation}

            //onMappingUpdated2={handleIncomeUpdated}
          />
        </div>
      )}
      {ShowGroupPlans === true && (
        <>
          {ShowCreateClientPresentation !== true && (
            <div>
              {/* <CFStructure */}
              <CombinedComponent
                clientCode={currentClientCode}
                ShowCreateClientPresentation={ShowCreateClientPresentation}

                //onMappingUpdated2={handleMappingUpdated2}
              />
            </div>
          )}

          {ShowCreateClientPresentation === true && (
            <div>
              <CombinedComponent
                clientCode={currentClientCode}
                ShowCreateClientPresentation={ShowCreateClientPresentation}

                //onMappingUpdated2={handleMappingUpdated2}
              />
            </div>
          )}
        </>
      )}

      {ShowCashFlowInput === true && (
        <div>
          <CFTax
            clientCode={currentClientCode}
            ShowCreateClientPresentation={ShowCreateClientPresentation}
            //onMappingUpdated2={handleMappingUpdated2}
          />
        </div>
      )}
      {CopyStructure === true && (
        <div>
          <CFCopyStructure
            clientCode={currentClientCode}
            onClose={closeForm}
            //onMappingUpdated2={handleMappingUpdated2}
          />
        </div>
      )}
      {EnterStructure === true && (
        <div>
          <ExcelForm
            clientCode={currentClientCode}
            onClose={closeForm}
            //onMappingUpdated2={handleMappingUpdated2}
          />
        </div>
      )}
      {EnterTaxStructure === true && (
        <div>
          <ExcelTaxForm
            clientCode={currentClientCode}
            onClose={closeForm}
            //onMappingUpdated2={handleMappingUpdated2}
          />
        </div>
      )}

      {ShowCreateClientPresentation === true &&
        showProgress !== true &&
        showDebtSummary !== true &&
        showNetWorth !== true &&
        ShowCashFlow !== true && (
          // showDebtSummary === false &&
          // showNetWorth === false &&
          <PresentationPage clientCode={currentClientCode} />
        )}
      {showAccountStatement && (
        <AccountStatement clientCode={currentClientCode} />
      )}
    </>
  );
};
export default ClientManagement;
// )}
// {ShowProcessImport && (
//   <ImportTransactions clientCode={currentClientCode} />
// )}
