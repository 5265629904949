import React, { useState, useEffect } from "react";
//>>import "./profile.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Button } from "devextreme-react/button";
import {
  fetchThisClientData,
  GenerateCarryingChargesData,
  GenerateCarryingChargesDetailData,
  getClients,
  //GenerateExcelFileForSend,
} from "../clientManagementData";
import "./extractBankListTransactions";
//import { fetchcompany } from "../../api/MyOwnServices";
import { fetchcompany } from "../../../api/MyOwnServices";
import { set } from "date-fns";
//import ExtractBankListTransactions from "./extractBankListTransactions";

import axios from "axios";
//>>import "./profile.scss";
//import { faSpinner } from "@fortawesome/free-solid-svg-icons";
//import Swal from "sweetalert2";
//import withReactContent from "sweetalert2-react-content";
//import { Button } from "devextreme-react/button";
//import { fetchcompany } from "../../../api/MyOwnServices";
import SelectBox from "devextreme-react/select-box";
import ExtractBankListTransactions from "./extractBankListTransactions";

//import { set } from "date-fns";

export const GenerateCarryingCharges = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [myClientCode, setClientCode] = useState(props.clientCode);
  const MySwal = withReactContent(Swal);
  const [startPeriod, setStartPeriod] = useState("");
  const [endPeriod, setEndPeriod] = useState("");
  const [filename, setFilename] = useState(""); // State to store the generated filename
  const [filename2, setFilename2] = useState(""); // State to store the generated filename
  const [ServerUsername, setServerUsername] = useState("");
  const [ServerPassword, setServerPassword] = useState("");
  const [ServerName, setServerName] = useState("");
  const [ServerDatabase, setServerDatabase] = useState("");
  const [Startdate, setStartDate] = useState("");
  const [enddate, setEndDate] = useState("");
  const companynumbersent = "0001";
  const [TransferSelected, setTransferSelected] = useState(false);

  const [YearList, setYearList] = useState([]);

  const [showExtractBankTransactions, setShowExtractBankTransactions] =
    useState(false);

  useEffect(() => {
    const fetchCompanyData = async () => {
      const result = await fetchcompany(companynumbersent);
      setServerUsername(result.ServerUsername);
      setServerPassword(result.ServerPassword);
      setServerName(result.ServerName);
      setServerDatabase(result.ServerDatabase);
    };

    fetchCompanyData();
  }, [companynumbersent]);

  const ProcessCarryingCharges = async (e) => {
    try {
      setIsLoading(true);
      const result = await GenerateCarryingChargesData(
        myClientCode,
        startPeriod
      );
      console.log("result", result);
      setFilename(result.filename);
      setStartDate(result.StartDate);
      setEndDate(result.enddate);
      console.log("File created - ", filename, " now send it");
      //const response = await GenerateExcelFileForSend(myClientCode);
      // console.log("File received");

      // if (response && response.filename) {
      //   setFilename(response.filename); // Save the filename to state
      // }
      if (result.filename === "System did not respond") {
        MySwal.fire({
          icon: "success",
          title: "Completed",
          text: "Carrying charges have been successfully generated.",
        });
      } else {
        MySwal.fire({
          icon: "success",
          title: "Completed",
          text: "Carrying charges have been successfully generated.",
        });
      }
    } catch (error) {
      console.error("Error processing interest:", error);
      MySwal.fire({
        icon: "error",
        title: "Error",
        text: "There was an issue processing the interest.",
      });
    } finally {
      setIsLoading(false);
      setTransferSelected(true);
    }
  };

  const ProcessCarryingDetailCharges = () => {
    setShowExtractBankTransactions(true); // Set state to render the child component
  };

  useEffect(() => {
    console.log("client code value", myClientCode);
    fetchThisClientData(myClientCode).then((data) => {
      console.log("client data", data);
      //setStartPeriod(data.startinterestperiod);
      //setEndPeriod(data.endinterestperiod);
    });
    generateYearList();
  }, [myClientCode]);

  const downloadFile = (filename) => {
    const url = `${process.env.REACT_APP_BASE_URL_AXIOS}/download/${filename}`;
    //const url = `http://localhost:4000/download/${filename}`;
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setFilename(""); // Clear the filename after download
  };

  const downloadFile2 = (filename) => {
    const url = `${process.env.REACT_APP_BASE_URL_AXIOS}/download/${filename2}`;
    //const url = `http://localhost:4000/download/${filename}`;
    const link = document.createElement("b");
    link.href = url;
    link.setAttribute("download2", filename2);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setFilename2(""); // Clear the filename after download
  };

  const generateYearList = () => {
    const years = [];
    for (let year = 2030; year >= 2020; year--) {
      years.push(year.toString());
    }
    setYearList(years); // Assuming `setYearList` is the state setter for the year list
    return years;
  };

  return (
    <>
      <div className="red-color responsive-paddingsx">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <label>
            Year:
            <SelectBox
              className="white-text-selectbox"
              style={{ width: "150px", height: "30px", marginTop: "5px" }}
              items={YearList}
              value={startPeriod}
              acceptCustomValue={true}
              onValueChanged={(e) => setStartPeriod(e.value)}
            />
          </label>
          <Button
            text=" Carrying Charges"
            onClick={ProcessCarryingCharges}
            style={{
              width: "250px",
              height: "30px",
              marginTop: "30px",
            }}
          ></Button>
          {TransferSelected && (
            <Button
              text=" Carrying Charges Details"
              onClick={ProcessCarryingDetailCharges}
              style={{
                width: "250px",
                height: "30px",
                marginTop: "30px",
              }}
            ></Button>
          )}
        </div>
      </div>
      {filename && (
        <div className="red-color responsive-paddingsx">
          <h3>File Download is ready</h3>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "50%",
            }}
          >
            <Button
              text="Download Carrying Charges Excel File"
              onClick={() => downloadFile(filename)}
              style={{ width: "350px", height: "30px", marginTop: "30px" }}
            ></Button>
          </div>
        </div>
      )}
      {filename2 && (
        <div className="red-color responsive-paddingsx">
          <h3>File Download is ready</h3>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "50%",
            }}
          >
            <Button
              text="Download Detail Carrying Charges Excel File"
              onClick={() => downloadFile2(filename2)}
              style={{ width: "350px", height: "30px", marginTop: "30px" }}
            ></Button>
          </div>
        </div>
      )}

      {isLoading && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "50vh",
          }}
        >
          <p style={{ marginBottom: "10px", fontSize: "24px" }}>
            Processing Carrying Charges please wait...
          </p>
          <FontAwesomeIcon icon={faSpinner} spin style={{ fontSize: "48px" }} />
        </div>
      )}
      {showExtractBankTransactions && (
        <ExtractBankListTransactions
          clientCode={props.clientCode}
          startDate={Startdate}
          endDate={enddate}
        />
      )}
    </>
  );
};
