import React, { useEffect } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import DataGrid, {
  Column,
  Paging,
  Editing,
  Lookup,
  RequiredRule,
  ValidationRules,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";

import { mystore13, getStockTypes } from "./clientInvestmentsData";

function ClientInvestmentsSummary(props) {
  const MySwal = withReactContent(Swal);
  const [investmentTypes, setInvestmentTypes] = React.useState([]);

  useEffect(() => {
    getStockTypes()
      .then((data) => {
        setInvestmentTypes(data.data);
      })
      .catch((error) => {
        console.error("Error fetching investment types:", error);
      });
  }, []);

  // SweetAlert2 for validation messages
  const showValidationMessage = () => {
    alert("A transaction code is required");
    // MySwal.fire({
    //   icon: "error",
    //   title: "Validation Error",
    //   text: "Transaction Type is required. Please select a value.",
    //   confirmButtonText: "OK",
    // });
  };

  // Validate `TRANSACTIONTYPE` on update
  const handleRowUpdating = (e) => {
    console.log("Updating Row:", e.newData, e.oldData);
    const transactionType =
      e.newData?.TRANSACTIONTYPE || e.oldData?.TRANSACTIONTYPE;
    if (!transactionType) {
      e.cancel = true;
      showValidationMessage();
    }
  };

  // Validate `TRANSACTIONTYPE` on insert
  const handleRowInserting = (e) => {
    console.log("Inserting Row:", e.data);
    if (!e.data?.TRANSACTIONTYPE) {
      e.cancel = true;
      showValidationMessage();
    }
  };

  const calculateTotalAmount = (amountOne, amountTwo) => {
    const amt1 = parseFloat(amountOne) || 0;
    const amt2 = parseFloat(amountTwo) || 0;
    console.log("Amount One:", amt1, "Amount Two:", amt2);
    return amt1 * amt2;
  };

  // useEffect(() => {
  //   MySwal.fire({
  //     icon: "success",
  //     title: "Test MySwal",
  //     text: "This is a test message to ensure MySwal works.",
  //     confirmButtonText: "OK",
  //   });
  // }, []);

  return (
    <div>
      <p>Stock Transactions</p>
      <DataGrid
        dataSource={mystore13(props.StockID)}
        showBorders={true}
        remoteOperations={false}
        width={"100%"}
        columnAutoWidth={true}
        paging={{ pageSize: 10 }}
        onRowUpdating={handleRowUpdating}
        onRowInserting={handleRowInserting}
        onEditorPreparing={(e) => {
          if (e.dataField === "QUANTITY" || e.dataField === "UNITPRICE") {
            e.editorOptions.onValueChanged = (args) => {
              e.setValue(args.value);

              const rowData = e.row?.data || {};
              const newQuantity =
                e.dataField === "QUANTITY" ? args.value : rowData.QUANTITY || 0;
              const newUnitPrice =
                e.dataField === "UNITPRICE"
                  ? args.value
                  : rowData.UNITPRICE || 0;

              // Calculate the new AMOUNT
              const totalAmount = newQuantity * newUnitPrice;

              // Update the AMOUNT field in the DataGrid
              e.component.cellValue(e.row.rowIndex, "AMOUNT", totalAmount);

              // Also update the data source
              rowData.AMOUNT = totalAmount;
            };
          }
        }}
      >
        <Editing
          mode="row"
          allowUpdating={false}
          allowAdding={true}
          allowDeleting={true}
        />
        <Column
          dataField={"NONCASHTRANSACTION"}
          caption="Non Cash"
          dataType="boolean"
          visible={true}
          allowEditing={false}
          width={150}
        />
        <Column
          dataField={"STOCKCODE"}
          width={150}
          caption={"Stock"}
          visible={true}
        />
        <Column
          dataField="TRANSACTIONTYPE"
          caption="Transaction Type"
          width={150}
          validationRules={[
            { type: "required", message: "Transaction Type is required." },
          ]}
        >
          <Lookup
            dataSource={investmentTypes}
            valueExpr="STOCKTRANSACTIONCODE"
            displayExpr="STOCKTRANSACTIONCODE"
          />
        </Column>
        <Column
          dataType="date"
          dataField={"TRANSACTIONDATE"}
          width={200}
          caption={"Date"}
          visible={true}
        />
        <Column
          dataField={"DESCRIPTION"}
          width={500}
          caption={"Description"}
          visible={true}
        />
        <Column
          dataField={"QUANTITY"}
          width={100}
          caption={"Units"}
          visible={true}
          format={"###,###,###.0000"}
        />
        <Column
          dataField={"UNITPRICE"}
          width={150}
          caption={"Price/Per"}
          visible={true}
          format={"$###,###,###.0000"}
        />
        <Column
          dataField={"AMOUNT"}
          width={150}
          caption={"Value"}
          visible={true}
          format={"$###,###,###.00"}
        />

        <Summary>
          {/* <TotalItem
            column="QUANTITY"
            summaryType="sum"
            displayFormat="Total Units: {0}"
          /> */}
          <TotalItem
            column="AMOUNT"
            summaryType="sum"
            displayFormat="Client Input: {0}"
            valueFormat="$###,###,###.00"
          />
        </Summary>
      </DataGrid>
    </div>
  );
}

export default ClientInvestmentsSummary;
