import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import FormulaParser from "fast-formula-parser";
import SelectBox from "devextreme-react/select-box";
import Spreadsheet from "react-spreadsheet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import {
  importstandard,
  GetInvestmentGroups,
  PullExcelcashFlow,
} from "./excelTaxFormData";
import "./spreadsheetStyles.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { th } from "date-fns/locale";
import { use } from "react";

const ExcelTaxForm = (props) => {
  const [customercode] = useState(props.clientCode);
  const [InvestmentGroups, setInvestmentGroups] = useState([]);
  const [currentGroup, setCurrentGroup] = useState("");
  const [hideColumns, setHideColumns] = useState(false);
  const [data, setData] = useState([]);
  const MySwal = withReactContent(Swal);
  const [isLoading, setIsLoading] = useState(false);

  const initialData = [
    [
      { value: "COL A" },
      { value: "COL B" },
      { value: "COL C" },
      { value: "COL D" },
      { value: "COL E" },
      { value: "COL F" },
      { value: "COL G" },
      { value: "COL H" },
    ],
  ];

  // useEffect(() => {
  //   GetInvestmentGroups()
  //     .then((response) => {
  //       setInvestmentGroups(response.data);
  //     })
  //     .catch((error) => {
  //       console.error("Error loading investment groups:", error);
  //     });
  // }, []);

  useEffect(() => {
    const updatedData = calculateData(initialData);
    setData(updatedData);
  }, [hideColumns]);

  const LoadDataFromStandard = () => {
    importstandard(customercode, currentGroup)
      .then((response) => {
        const transformedData = transformData(response.data);
        setData(transformedData);
      })
      .catch((error) => {
        console.error("Error loading data from standard:", error);
      });
  };

  const transformData = (data) => {
    return data.map((item) => {
      const isHeader = item.LINETYPE === "H";
      const isFooter = item.LINETYPE === "T";
      const commonClass = isHeader
        ? "blackongold"
        : isFooter
        ? "blackongrey"
        : "";

      return [
        {
          value: item.COLA || "",
          className: hideColumns ? "hidden-column" : "",
        },
        {
          value: item.COLB || "",
          className: isHeader ? "whiteonblack" : commonClass,
        },
        { value: item.COLC ?? "", className: commonClass },
        { value: item.COLD ?? "", className: commonClass },
        { value: item.COLE ?? "", className: commonClass },
        { value: item.COLF || "", className: commonClass },
        { value: item.COLE || "", className: commonClass },
      ];
    });
  };

  const calculateData = (currentData) => {
    return currentData.map((row, rowIndex) => {
      if (rowIndex === 0) return row;
      return row.map((cell) => {
        return {
          ...cell,
          className: hideColumns ? "hidden-column" : cell.className,
        };
      });
    });
  };

  const parser = new FormulaParser({
    onCell: ({ row, col }) => {
      const refCell = data[row - 1]?.[col - 1];
      if (!refCell) return 0; // Default to 0 if the cell doesn't exist

      const value =
        typeof refCell.value === "string" && refCell.value.startsWith("=")
          ? evaluateCell(refCell, row - 1, col - 1)
          : refCell.value;

      console.log("value", value);
      return !isNaN(Number(value)) ? Number(value) : 0; // Return numeric value or 0
    },
    onRange: ({ from, to }) => {
      const result = [];
      for (let row = from.row; row <= to.row; row++) {
        for (let col = from.col; col <= to.col; col++) {
          const cell = data[row - 1]?.[col - 1];
          const value =
            typeof cell?.value === "string" && cell.value.startsWith("=")
              ? evaluateCell(cell, row - 1, col - 1)
              : cell?.value;

          result.push(!isNaN(Number(value)) ? Number(value) : 0); // Default to 0
        }
      }
      return result;
    },
  });

  const evaluateCell = (cell, rowIndex, colIndex, evaluatedCache = {}) => {
    return cell.value;
  };

  const handleSave = async () => {
    setIsLoading(true);
    const evaluatedCache = {};

    //First pass: Precompute all dependent formulas
    const firstPassData = data.map((row, rowIndex) =>
      row.map((cell, colIndex) =>
        evaluateCell(cell, rowIndex, colIndex, evaluatedCache)
      )
    );

    console.log("First Pass Evaluated Data:", firstPassData);

    // Save to Excel file
    // const worksheet = XLSX.utils.aoa_to_sheet(firstPassData);
    // const workbook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    // XLSX.writeFile(workbook, "SpreadsheetData.xlsx");

    // Transform data for backend
    const transformedData = firstPassData.map((row) => ({
      cola: row[0],
      colb: row[1],
      colc: row[2],
      cold: row[3],
      cole: row[4],
      colf: row[2],
      colg: row[3],
      linetype: row[4],
    }));

    console.log("Transformed Data for Backend:", transformedData);

    try {
      await UpdateCFDataIncomeTaxExcel(
        customercode,
        transformedData,
        currentGroup
      );
      console.log("Data saved to backend successfully.");
    } catch (error) {
      console.error("Error saving data to backend:", error);
    }
    try {
      //await GenerateExcelTaxFlow(customercode, currentGroup);
      console.log("Data saved to backend successfully.");
    } catch (error) {
      console.error("Error saving data to backend:", error);
    }
    setCurrentGroup("");
    setIsLoading(false);
  };

  const handleChange = (updatedData) => {
    setData(updatedData);
  };

  const setCurrentFGGroup = (e) => {
    setCurrentGroup(e.value);
    //pull the current values from the backend
  };
  useEffect(() => {
    setIsLoading(true);
    PullExcelcashFlow(customercode, currentGroup)
      .then((response) => {
        console.log("response", response);
        const transformedData = transformData(response.data);
        setData(transformedData);
      })
      .catch((error) => {
        console.error("Error loading data from standard:", error);
      });
    setIsLoading(false);
  }, [currentGroup]);

  return (
    <div className="spreadsheet-container">
      <div>
        <div className="app2" style={{ display: "flex", alignItems: "left" }}>
          <div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(6, auto)",
                gap: "10px",
              }}
            >
              <SelectBox
                className="white-text-selectbox"
                style={{ width: "200px", height: "40px", marginRight: "10px" }}
                items={InvestmentGroups}
                valueExpr="REPORTGROUP"
                displayExpr="REPORTGROUP"
                value={currentGroup}
                searchEnabled={true}
                onValueChanged={setCurrentFGGroup}
              />
              <div>
                {currentGroup && (
                  <>
                    <button
                      className="styled-button"
                      onClick={() => setHideColumns((prev) => !prev)}
                    >
                      {hideColumns ? "Show Columns" : "Hide Columns"}
                    </button>
                    <button
                      className="styled-button"
                      onClick={() => LoadDataFromStandard(customercode)}
                    >
                      Import From Standard
                    </button>
                    <button
                      className="styled-button save-button"
                      onClick={handleSave}
                    >
                      Save Data to File
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading && (
        <div
          className="spinner-container"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "50vh", // Full height of the viewport for proper vertical centering
          }}
        >
          <p style={{ marginBottom: "100px", fontSize: "24px" }}>
            Processing, please wait &nbsp;&nbsp;
          </p>
          <FontAwesomeIcon
            icon={faSpinner}
            spin
            className="large-spinner"
            style={{ fontSize: "48px" }} // Larger spinner size
          />
        </div>
      )}

      <h2>Financial Spreadsheet Data Entry for {customercode}</h2>

      <Spreadsheet data={data} onChange={handleChange} />
    </div>
  );
};

export default ExcelTaxForm;

export const UpdateCFDataIncomeTaxExcel = async (
  myClient,
  elementarray,
  type
) => {
  const requestoptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json;",
    },
    body: JSON.stringify({
      sentclientcode: myClient,
      assetarray: elementarray,
      reporttype: type,
    }),
  };
  //const url = `${process.env.REACT_APP_BASE_URL}/updateCFIncomeDataExcel2`;
  const url = `${process.env.REACT_APP_BASE_URL}/UpdateCFDataIncomeTaxExcel`;
  const response = await fetch(url, requestoptions);
  if (!response.ok) {
    return { data: [], error: "System did not respond" };
  }
  const json = await response.json();
  return { data: json.user_response.bankq };
};

export const GenerateExcelcashFlow = async (myClient, currentGroup) => {
  const requestoptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json;",
    },
    body: JSON.stringify({
      ClientCode: myClient,
      currentGroup: currentGroup,
    }),
  };
  const url = `${process.env.REACT_APP_BASE_URL}/GenerateExcelcashFlow`;
  const response = await fetch(url, requestoptions);
  if (!response.ok) {
    return { data: [], error: "System did not respond" };
  }
  const json = await response.json();
  return { data: json.user_response.bankq };
};
