export const navigation = [
  {
    text: "Registration",
    path: "/notRegistered",

    auth: "X",
  },

  {
    text: "User Information",
    path: "/profile",

    auth: "N",
  },

  {
    text: "Bank Accounts",
    path: "/bankAccountSummary",

    auth: "N",
  },
  {
    text: "Unallocated Bank Transactions",
    path: "/unallocatedBankTransactions",

    auth: "N",
  },
  {
    text: "Investments",
    path: "/investmentSummary",

    auth: "N",
  },
  {
    text: "Authorizations/Setup",

    auth: "N",
    items: [
      {
        text: "Authorize Banks",
        path: "/authorizeUser",
      },

      {
        text: "Authorize Investments",
        path: "/authorizeInvestmentUser",
      },
      {
        text: "Change Password",
        path: "/changePassword",
      },

      {
        text: "Validate Accounts",
        path: "/validateExistingAccounts",
        icon: "revert",
      },
    ],
  },
  {
    text: "Analysis Tools",

    auth: "N",
    items: [
      {
        text: "Net Assets",
        path: "/netAssets",
      },
      {
        text: "Net Worth",
        path: "/netWorth",
      },
    ],
  },
  {
    text: "   Admin",

    auth: "Y",
    expanded: "N",
    items: [
      {
        text: "Company Profile",

        auth: "Y",
        path: "/company",
      },
      {
        text: "Transaction Groups",

        auth: "Y",
        path: "/transactionGroups",
      },
      {
        text: "Transaction Types",

        auth: "Y",
        path: "/transactionTypes",
      },
      {
        text: "Code Mapping",

        auth: "Y",
        path: "/codeMapping",
      },
      {
        text: "Asset Groups",

        auth: "Y",
        path: "/assetTypeGroups",
      },
      {
        text: "Asset Types",

        auth: "Y",
        path: "/assetTypes",
      },
      {
        text: "Banks",

        auth: "Y",
        path: "/banks",
      },
      {
        text: "Bank Account Types",
        auth: "Y",
        path: "/bankAccountTypes",
      },
      {
        text: "Investment Banks",

        auth: "Y",
        path: "/investmentBanks",
      },
      { text: "Net Worth Setup", path: "/netWorth", auth: "Y" },

      {
        text: "Invest Groups",

        auth: "Y",
        path: "/investmentGroups",
      },
      {
        text: "Invest Subgroups",

        auth: "Y",
        path: "/investmentSubGroups",
      },
      {
        text: "Invest Types",
        auth: "Y",
        path: "/investmentTypes",
      },
      {
        text: "Invest Trans Types",
        auth: "Y",
        path: "/stockTransactionTypes",
      },
      {
        text: "New Clients",
        auth: "Y",
        path: "/newClients",
      },
      {
        text: "Inc/Tax Stmnt Report Groups",
        auth: "Y",
        path: "/incomeStatementReportGroups",
      },
      // {
      //   text: "Extract Inc/Tax Statement Types",
      //   auth: "Y",
      //   path: "/extractTaxStatements",
      // },
      // {
      //   text: "Import Inc/Tax Statement Types",
      //   auth: "Y",
      //   path: "/importIncTax",
      // },
      // {
      //   text: "Inc/Tax Stmnt Types",
      //   auth: "Y",
      //   path: "/incomeStatementTypes",
      // },
      {
        text: "Import Flow/Tax/Structure ",
        auth: "Y",
        path: "/importCashFlow",
      },
      {
        text: "Cash Flow/Tax/Structure",
        auth: "Y",
        path: "/incomeStatementReportDetails",
      },

      {
        text: "List Clients",

        auth: "Y",
        path: "/clientProfiles",
      },
      {
        text: "Security",
        auth: "Y",
        path: "/clientOwnersSecurity",
      },
      {
        text: "Prime Rates",
        auth: "Y",
        path: "/primeRates",
      },
    ],
  },

  {
    text: "Client",

    path: "/clientManagement",
    auth: "Y",
  },
  {
    text: "Import",

    path: "/importExcelAssets",
    auth: "Y",
  },
  {
    text: "Extract Bank List",
    path: "/extractBankList",
    auth: "Y",
  },
];
