import React, { useState, useEffect } from "react";
import ExcelJS from "exceljs";
import withReactContent from "sweetalert2-react-content";
import { saveAs } from "file-saver";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import { useAuth } from "../../contexts/auth";
import { Button } from "devextreme-react/button";
import DataGrid, {
  Column,
  Paging,
  FilterRow,
  HeaderFilter,
  Editing,
  Lookup,
} from "devextreme-react/data-grid";
import { fetchcompany } from "../../api/MyOwnServices";
import {
  mystore11,
  getCFIncomeTypesDataFilter,
  ResequenceIncomeData,
  CFIncomeMasterData,
  getReportGroups,
  CFStatementTypesexcel,
} from "./incomeStatementReportDetailsData";
//import CFIncomeResult from "./CFIncomeResult";
import SelectBox from "devextreme-react/select-box";

const CFIncome = (props) => {
  const [OwnerNames, setOwnerNames] = useState([]);
  const [StatementTypes, setStatementTypes] = useState([]);
  const [IncomeStatementType, setIncomeStatementType] = useState("");
  const [ReportGroups, setReportGroups] = useState([]);
  const [companynumber, setCompanyNumber] = useState(1);
  const [UpdateType, setUpdateType] = useState([]);
  const [loadForm, setLoadForm] = useState(false);
  const [ShowCreateClientPresentation, setShowCreateClientPresentation] =
    useState(props.ShowCreateClientPresentation);
  const [resetKey, setResetKey] = useState(0);

  useEffect(() => {
    if (ShowCreateClientPresentation) {
      setLoadForm(true);
    }

    mystore11(props.clientCode)
      .then((data) => {
        setOwnerNames(data.data);
      })
      .catch((error) => {
        console.error(
          "There was an error fetching the transaction group data:",
          error
        );
      });
    fetchcompany(companynumber).then((result) => {
      setIncomeStatementType(result.IncomeStatementType);
      setUpdateType(result.IncomeStatementType);
    });
    getReportGroups(props.clientCode)
      .then((data) => {
        setReportGroups(data.data);
      })
      .catch((error) => {
        console.error(
          "There was an error fetching the report group data:",
          error
        );
      });
  }, [props.clientCode, companynumber]);

  useEffect(() => {
    if (IncomeStatementType && IncomeStatementType.trim() !== "") {
      getCFIncomeTypesDataFilter(IncomeStatementType)
        .then((data) => {
          setStatementTypes(data.data);
          console.log("data here", data.data);
        })
        .catch((error) => {
          console.error(
            "There was an error fetching the transaction group data:",
            error
          );
        });
    }
  }, [IncomeStatementType]);

  const handleInitNewRow = (e) => {
    const dataSource = e.component.getDataSource().items();
    const highestItem =
      dataSource.length > 0
        ? Math.max(...dataSource.map((item) => parseInt(item.ITEM, 10)))
        : 0;
    const newItemValue = highestItem + 10;
    e.data.DONOTPRINT = false;
    e.data.REPORTTYPE = IncomeStatementType;
    e.data.ITEM = newItemValue.toString();
  };
  const LoadForm = () => {
    loadForm ? setLoadForm(false) : setLoadForm(true);
  };

  const ResequenceIncome = () => {
    ResequenceIncomeData(props.clientCode, IncomeStatementType).then((data) => {
      setResetKey(resetKey + 1);
    });
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <p> </p>
            <SelectBox
              className="white-text-selectbox"
              style={{ width: "200px", height: "40px", marginRight: "10px" }} // Adjusted to include marginRight
              items={ReportGroups}
              valueExpr="REPORTGROUP"
              displayExpr="DESCRIPTION"
              value={IncomeStatementType}
              searchEnabled={true}
              onValueChanged={(e) => setIncomeStatementType(e.value)} // Correctly handle the event
            />
            {/* <SelectBox
              className="white-text-selectbox"
              style={{ width: "200px", height: "40px", marginRight: "10px" }} // Adjusted to include marginRight
              items={StatementTypes}
              valueExpr="REPORTGROUP"
              displayExpr="DESCRIPTION"
              value={IncomeStatementType}
              searchEnabled={true}
              onValueChanged={(e) => setIncomeStatementType(e.value)} // Correctly handle the event
            /> */}
          </div>
          <div className="content-block2 dx-card responsive-paddings">
            {ShowCreateClientPresentation !== true && (
              <>
                <Button
                  text="Load Form"
                  onClick={LoadForm}
                  style={{
                    width: "200px",
                    height: "30px",
                    marginTop: "2px",
                    marginBottom: "10px",
                    marginLeft: "15px",
                  }}
                ></Button>
                <Button
                  text="Resequence"
                  onClick={ResequenceIncome}
                  style={{
                    width: "200px",
                    height: "30px",
                    marginTop: "2px",
                    marginBottom: "10px",
                    marginLeft: "15px",
                  }}
                ></Button>
              </>
            )}

            {!loadForm && (
              <div>
                <DataGrid
                  dataSource={CFStatementTypesexcel(IncomeStatementType)}
                  keyExpr="UNIQUEID"
                  key={resetKey}
                  showBorders={true}
                  remoteOperations={false}
                  onInitNewRow={handleInitNewRow}
                >
                  <Editing
                    mode="cell"
                    allowUpdating={true}
                    allowAdding={true}
                    allowDeleting={true}
                  />
                  <Paging enabled={false} />
                  <Column
                    dataField="UNIQUEID"
                    caption="Unique ID"
                    visible={false}
                  />
                  <Column
                    dataField="REPORTGROUP"
                    caption="Report Type"
                    visible={false}
                  />

                  <Column
                    dataField="REPORTINGSEQUENCE"
                    caption="Item"
                    width={100}
                  />
                  <Column
                    dataField="DESCRIPTION"
                    caption="Description"
                    width={300}
                  />

                  <Column
                    dataField="HIMFORMULA"
                    caption="Formula A"
                    visible={true}
                  />
                  <Column
                    dataField="HERFORMULA"
                    caption="Formula B"
                    visible={true}
                  />
                  <Column
                    dataField="TOTALFORMULA"
                    caption="Formula C"
                    visible={true}
                  />
                  <Column
                    dataField="LINETYPE"
                    caption="Line Type"
                    visible={true}
                  />
                </DataGrid>
              </div>
            )}
            {/* {loadForm && (
              <CFIncomeResult
                clientCode={props.clientCode}
                ShowCreateClientPresentation={ShowCreateClientPresentation}
              />
            )} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default CFIncome;
