import React, { useState, useEffect } from "react";
import ExcelJS from "exceljs";
import withReactContent from "sweetalert2-react-content";
import { saveAs } from "file-saver";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import { useAuth } from "../../contexts/auth";
import { Button } from "devextreme-react/button";
import DataGrid, {
  Column,
  Paging,
  FilterRow,
  HeaderFilter,
  Editing,
  Lookup,
} from "devextreme-react/data-grid";
import { fetchcompany } from "../../api/MyOwnServices";
import { GetPrimeRatesData } from "./PrimeRatesData";
//import CFIncomeResult from "./CFIncomeResult";
import SelectBox from "devextreme-react/select-box";

const PrimeRates = (props) => {
  const [OwnerNames, setOwnerNames] = useState([]);
  const [StatementTypes, setStatementTypes] = useState([]);
  const [IncomeStatementType, setIncomeStatementType] = useState("");
  const [ReportGroups, setReportGroups] = useState([]);
  const [companynumber, setCompanyNumber] = useState(1);
  const [UpdateType, setUpdateType] = useState([]);
  const [loadForm, setLoadForm] = useState(false);
  const [ShowCreateClientPresentation, setShowCreateClientPresentation] =
    useState(props.ShowCreateClientPresentation);
  const [resetKey, setResetKey] = useState(0);

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div>
          <div
            className="content-block2 dx-card responsive-paddings"
            style={{ width: "40%" }}
          >
            <div>
              <DataGrid
                dataSource={GetPrimeRatesData(props.clientCode)}
                keyExpr="UNIQUEID"
                key={resetKey}
                showBorders={true}
                remoteOperations={false}
              >
                <Editing
                  mode="cell"
                  allowUpdating={true}
                  allowAdding={true}
                  allowDeleting={true}
                />
                <Paging enabled={false} />

                <Column
                  dataType="date"
                  dataField={"PRIMERATEDATE"}
                  caption="Date (MM/DD/YYYY)"
                  hidingPriority={7}
                  allowEditing={true}
                />

                <Column
                  dataField="RATE"
                  visible={true}
                  width={200}
                  caption="Rate"
                ></Column>
              </DataGrid>
            </div>

            {/* {loadForm && (
              <CFIncomeResult
                clientCode={props.clientCode}
                ShowCreateClientPresentation={ShowCreateClientPresentation}
              />
            )} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default PrimeRates;
