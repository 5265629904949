import React, { useEffect, useState } from "react";
import "./importCashFlow.scss";
//import SelectBox from "devextreme-react/select-box";
import {
  getBanks,
  updateExcelTransactions,
  createAssetsandInvestments,
  ImportExcelData,
} from "./importCashFlowData";
// import "./FileUploader.css"; // Importing the CSS file

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";
import { set } from "date-fns";
import { Button } from "devextreme-react/button";

import DataGrid, {
  Column,
  Paging,
  Pager,
  Sorting,
  Editing,
  FilterRow,
  Item,
  Lookup,
  Popup,
  Form,
  MasterDetail,
  HeaderFilter,
  Search,
  SearchPanel,
} from "devextreme-react/data-grid";
import { column } from "mathjs";
import { Col } from "devextreme-react/responsive-box";

//import { CheckBox } from "devextreme-react/check-box";

const ExcelJS = require("exceljs");

const ImportTaxStatements = (props) => {
  const [bankAccounts, setBankAccounts] = useState([]);
  const [currentBankAccountName, setCurrentBankAccountName] = useState("");
  const [currentBankAccount, setCurrentBankAccount] = useState("");
  //const [accountData, setAccountData] = useState("");
  const [ClientCode, setClientCode] = useState(props.clientCode);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showInfo, setShowInfo] = useState(false);
  const [headers, setHeaders] = useState([]); // State for storing headers
  const [rows, setRows] = useState([]); // State for storing row data
  const MySwal = withReactContent(Swal);

  const [editMode, setEditMode] = useState(false); // To toggle edit mode
  const navigate = useNavigate(); // Inside your component
  const [ShowPage, setShowPage] = useState(true); // To toggle edit mode

  const [assets, setAssets] = useState([]);
  const [investments, setInvestments] = useState([]);

  const [assetHeaders, setAssetHeaders] = useState([]);
  const [investmentHeaders, setInvestmentHeaderes] = useState([]);

  const [thisClientCode, setThisClientCode] = useState(""); // To store the client code

  const [headerDates, setHeaderDates] = useState([]); // To store the header dates

  const [importInvestments, setImportInvestments] = useState(true); // To store the header dates
  const [importAssets, setImportAssets] = useState(true); // To store the header dates

  const checkedLabel = { "aria-label": "Checked" };

  const [excelData, setExcelData] = useState({
    cashflow: [],
    tax: [],
    structure: [],
    taxcalculator: [],
    rates: [],
  });

  // const [columnMappings, setColumnMappings] = useState({
  //   date: "",
  //   description: "",
  //   debit: "",
  //   credit: "",
  // }); // To store user-specified column mappings

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    readExcelFile(file);
    setShowInfo(true);
  };
  //const readExcelFile = (file) => {

  const readExcelFile = async (file) => {
    const workbook = new ExcelJS.Workbook();
    const arrayBuffer = await file.arrayBuffer();
    await workbook.xlsx.load(arrayBuffer);

    const combinedData = []; // Array to hold data from all worksheets

    // let rowCount = 0;
    // const maxRows = 2;

    // Define a function to process rows from a worksheet
    const processWorksheet = (worksheet, sheetName) => {
      worksheet.eachRow((row, rowNumber) => {
        // if (rowCount >= maxRows) {
        //   return;
        // }
        const rowValues = row.values;

        // Skip the first two rows
        // if (rowNumber <= 2) {
        //   return;
        // }

        // Check if column A (rowValues[1]) is numeric
        if (
          isNaN(rowValues[1]) ||
          rowValues[1] === null ||
          rowValues[1] === ""
        ) {
          return; // Skip rows where column A is not numeric
        }

        // Extract columns A, B, G, H, and I
        combinedData.push({
          sheetName, // Include the sheet name for context
          sheetName,
          columnA: row.getCell(1).formula
            ? `=${row.getCell(1).formula}` // Prepend '=' if it’s a formula
            : row.getCell(1).value || "", // Otherwise, use the value
          columnB: row.getCell(2).formula
            ? `=${row.getCell(2).formula}`
            : row.getCell(2).value || "",
          ColumnC: row.getCell(3).formula
            ? `=${row.getCell(3).formula}`
            : row.getCell(3).value || "",
          ColumnD: row.getCell(4).formula
            ? `=${row.getCell(4).formula}`
            : row.getCell(4).value || "",
          ColumnE: row.getCell(5).formula
            ? `=${row.getCell(5).formula}`
            : row.getCell(5).value || "",
          columnF: row.getCell(6).formula
            ? `=${row.getCell(6).formula}`
            : row.getCell(6).value || "",
          columnG: row.getCell(7).formula
            ? `=${row.getCell(7).formula}`
            : row.getCell(7).value || "",
          // columnA: row.getCell(1).formula || row.getCell(1).value || "",
          // columnB: row.getCell(2).formula || row.getCell(2).value || "",
          // ColumnC: row.getCell(3).formula || row.getCell(3).value || "",
          // ColumnD: row.getCell(4).formula || row.getCell(4).value || "",
          // ColumnE: row.getCell(5).formula || row.getCell(5).value || "",
          // columnF: row.getCell(6).formula || row.getCell(6).value || "",
          // columnG: row.getCell(7).formula || row.getCell(7).value || "",

          // columnA: row.getCell(1).value || "",
          // columnB: row.getCell(2).value || "",
          // ColumnC: row.getCell(3).value || "",
          // ColumnD: row.getCell(4).value || "",
          // ColumnE: row.getCell(5).value || "",
          // columnF: row.getCell(6).value || "",
        });
        //rowCount++;
      });
    };

    // Process each worksheet by name
    const worksheets = [
      "CashFlow",
      "Tax",
      "Structure",
      "Tax Calculator",
      "Rates",
    ];
    worksheets.forEach((sheetName) => {
      console.log("Processing worksheet:", sheetName);
      const worksheet = workbook.getWorksheet(sheetName);
      if (worksheet) {
        processWorksheet(worksheet, sheetName);
      }
    });

    // Log the combined data
    console.log("Combined Data:", combinedData);

    // Update state with combined data
    setExcelData(combinedData);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      readExcelFile(file);
    }
  };

  const removeOuterObject = (data) => {
    // Check if 'excelData' key exists and is an array
    if (data && Array.isArray(data.excelData)) {
      return data.excelData; // Return the inner array
    }
    throw new Error(
      "Invalid structure: 'excelData' is missing or not an array"
    );
  };

  const handleFileImport = async () => {
    if (!excelData || excelData.length === 0) {
      MySwal.fire({
        icon: "error",
        title: "Error",
        text: "No data available to import. Please upload a valid file.",
      });
      return;
    }

    //Build the payload with correct structure

    const payload = {
      excelData: excelData.map((row) => ({
        sheetName: row.sheetName || "", // Handle null or undefined sheetName
        columnA: row.columnA != null ? row.columnA : "", // Handle both null and undefined
        columnB: row.columnB != null ? row.columnB : "",
        columnC: row.ColumnC != null ? row.ColumnC : "",
        columnD: row.ColumnD != null ? row.ColumnD : "",
        columnE: row.ColumnE != null ? row.ColumnE : "",
        columnF: row.columnF != null ? row.columnF : "",
        columng: row.columng != null ? row.columng : "",
        // columnH: row.columnH != null ? row.columnH : "",
        // columnI: row.columnI != null ? row.columnI : "",
        // columnJ: row.columnJ != null ? row.columnJ : "",
        // columnK: row.columnK != null ? row.columnK : "",
      })),
    };

    // Flatten the data if required
    const flattenedData = excelData.map((row) => ({
      ReportType: row.sheetName || "",
      Sequence: row.columnA != null ? row.columnA : "",
      Description: row.columnB != null ? row.columnB : "",
      himvalue: row.ColumnC != null ? row.ColumnC : "",
      hervalue: row.ColumnD != null ? row.ColumnD : "",
      totalvalue: row.ColumnE != null ? row.ColumnE : "",
      LineType: row.columnF != null ? row.columnF : "",
      colg: row.columnG != null ? row.columnG : "",
      // Description2: row.columnH != null ? row.columnH : "",
      // Formula: row.columnI != null ? row.columnI : "",
      // LineType: row.columnJ != null ? row.columnJ : "",
      // StandardLine: row.columnK != null ? row.columnK : "",
    }));

    console.log("Structured Payload:", payload);
    console.log("Flattened Data:", flattenedData);

    try {
      const response = await ImportExcelData(flattenedData); // Send the payload to API
      MySwal.fire({
        icon: "success",
        title: "Data Imported",
        text: `Data has been imported successfully.`,
      });
      console.log("API Response:", response);
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Import Failed",
        text: "An error occurred while importing data. Please try again.",
      });
      console.error("Import error:", error);
    }
  };

  function handleimportInvestmentsChange(e) {
    setImportInvestments(e.value);
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "20px",
          justifyContent: "left",
          alignItems: "center",
        }}
      >
        <p></p>

        <div>
          <h2>Excel Cash Flow Import</h2>
          <input
            type="file"
            onChange={handleFileChange}
            accept=".xlsx, .xls"
            style={{
              width: "200px",
              height: "30px",
              marginTop: "2px",
              marginBottom: "10px",
              marginLeft: "15px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              padding: "4px",
              fontSize: "14px",
              cursor: "pointer",
            }}
          />
          <div style={{ marginTop: "20px" }}></div>
          <Button
            text="Import Data"
            onClick={handleFileImport}
            style={{
              width: "200px",
              height: "30px",
              marginTop: "2px",
              marginBottom: "10px",
              marginLeft: "15px",
            }}
          />
        </div>
        <div>
          <h2>Combined Excel Data</h2>
          {excelData.length > 0 ? (
            <DataGrid
              dataSource={excelData}
              showBorders={true}
              columnAutoWidth={true}
              keyExpr="sheetName" // Use sheetName or another unique key
            >
              <Column dataField="sheetName" caption="Sheet Name" />
              <Column dataField="columnA" caption="Column A" />
              <Column dataField="columnB" caption="Column B" />
              <Column dataField="ColumnC" caption="Column C" />
              <Column dataField="ColumnD" caption="Column D" />
              <Column dataField="ColumnE" caption="Column E" />
              <Column dataField="columnF" caption="Column F" />
              <Column dataField="columnG" caption="Column G" />
              {/* <Column dataField="columnH" caption="Column H" />
              <Column dataField="columnI" caption="Column I" />
              <Column dataField="columnJ" caption="Column J" />
              <Column dataField="columnK" caption="Column K" /> */}
            </DataGrid>
          ) : (
            <p>No data available</p>
          )}
        </div>
      </div>
    </>
  );
};

export default ImportTaxStatements;

const createIncTax = () => {};
