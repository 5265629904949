import React, { useState, useEffect } from "react";
import ExcelJS from "exceljs";
import withReactContent from "sweetalert2-react-content";
import { saveAs } from "file-saver";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import { useAuth } from "../../../contexts/auth";
import { Button } from "devextreme-react/button";
import DataGrid, {
  Column,
  Paging,
  FilterRow,
  HeaderFilter,
  Editing,
  Lookup,
} from "devextreme-react/data-grid";
import { fetchcompany } from "../../../api/MyOwnServices";
import {
  CFIncomeData,
  mystore11,
  getCFIncomeTypesDataFilter,
  ResequenceIncomeData,
} from "./cfData";
import CFStructureResult from "./cfStructureResult";

const CFStructure = (props) => {
  const [OwnerNames, setOwnerNames] = useState([]);
  const [StatementTypes, setStatementTypes] = useState([]);
  const [TaxStatementType, setTaxStatementType] = useState("");
  const [companynumber, setCompanyNumber] = useState(1);
  const [UpdateType, setUpdateType] = useState([]);
  const [loadForm, setLoadForm] = useState(true);
  const [ShowCreateClientPresentation, setShowCreateClientPresentation] =
    useState(props.ShowCreateClientPresentation);
  const [resetKey, setResetKey] = useState(0);

  useEffect(() => {
    if (ShowCreateClientPresentation) {
      setLoadForm(true);
    }

    mystore11(props.clientCode)
      .then((data) => {
        setOwnerNames(data.data);
      })
      .catch((error) => {
        console.error(
          "There was an error fetching the transaction group data:",
          error
        );
      });
    fetchcompany(companynumber).then((result) => {
      setTaxStatementType(result.StructureStatementType);
      setUpdateType(result.TaxStatementType);
    });
  }, [props.clientCode, companynumber]);

  useEffect(() => {
    if (TaxStatementType && TaxStatementType.trim() !== "") {
      getCFIncomeTypesDataFilter(TaxStatementType)
        .then((data) => {
          setStatementTypes(data.data);
        })
        .catch((error) => {
          console.error(
            "There was an error fetching the transaction group data:",
            error
          );
        });
    }
  }, [TaxStatementType]);

  const handleInitNewRow = (e) => {
    if (TaxStatementType && TaxStatementType.trim() !== "") {
      e.data.REPORTTYPE = TaxStatementType;
    }
  };

  const LoadForm = () => {
    loadForm ? setLoadForm(false) : setLoadForm(true);
  };

  const calculateTotalAmount = (amountOne, amountTwo) => {
    const amt1 = parseFloat(amountOne) || 0;
    const amt2 = parseFloat(amountTwo) || 0;
    return amt1 + amt2;
  };

  const ResequenceIncome = () => {
    ResequenceIncomeData(props.clientCode, TaxStatementType).then((data) => {
      setResetKey(resetKey + 1);
    });
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div>
          <div className="content-block2 dx-card responsive-paddings">
            {ShowCreateClientPresentation !== true && (
              <>
                <Button
                  text="Load Form"
                  onClick={LoadForm}
                  style={{
                    width: "200px",
                    height: "30px",
                    marginTop: "2px",
                    marginBottom: "10px",
                    marginLeft: "15px",
                  }}
                ></Button>
                <Button
                  text="Resequence"
                  onClick={ResequenceIncome}
                  style={{
                    width: "200px",
                    height: "30px",
                    marginTop: "2px",
                    marginBottom: "10px",
                    marginLeft: "15px",
                  }}
                ></Button>
              </>
            )}
            {!loadForm && (
              <div>
                <DataGrid
                  dataSource={CFIncomeData(props.clientCode, TaxStatementType)}
                  keyExpr="UNIQUEID"
                  showBorders={true}
                  remoteOperations={false}
                  onInitNewRow={handleInitNewRow}
                  onEditorPreparing={(e) => {
                    if (
                      e.dataField === "AMOUNTONE" ||
                      e.dataField === "AMOUNTTWO"
                    ) {
                      e.editorOptions.onValueChanged = (args) => {
                        e.setValue(args.value);
                        const data = e.row && e.row.data;
                        if (data) {
                          const newAmountOne =
                            e.dataField === "AMOUNTONE"
                              ? args.value
                              : data.AMOUNTONE;
                          const newAmountTwo =
                            e.dataField === "AMOUNTTWO"
                              ? args.value
                              : data.AMOUNTTWO;
                          const totalAmount = calculateTotalAmount(
                            newAmountOne,
                            newAmountTwo
                          );
                          e.component.cellValue(
                            e.row.rowIndex,
                            "TOTALAMOUNT",
                            totalAmount
                          );
                        }
                      };
                    }
                  }}
                >
                  <Editing
                    mode="cell"
                    allowUpdating={true}
                    allowAdding={true}
                    allowDeleting={true}
                  />
                  <Paging enabled={false} />
                  <Column
                    dataField="UNIQUEID"
                    caption="Unique ID"
                    visible={false}
                  />
                  <Column
                    dataField="REPORTTYPE"
                    caption="Report Type"
                    visible={false}
                  />
                  <Column
                    dataField="CLIENTCODE"
                    caption="Client"
                    visible={false}
                  />
                  <Column dataField="TYPE" visible={true} width={200}>
                    <Lookup
                      dataSource={StatementTypes}
                      valueExpr="TYPE"
                      displayExpr="DESCRIPTION"
                    />
                  </Column>
                  <Column dataField="ITEM" caption="Item" width={100} />
                  <Column
                    dataField="LINETYPE"
                    caption="Line Type"
                    visible={true}
                    width={150}
                  />
                  <Column
                    dataField="DESCRIPTION"
                    caption="Description"
                    width={300}
                  />
                  <Column
                    dataField="AMOUNTONE"
                    caption="Owner 1"
                    format={"$###,###,###"}
                    alignment="right"
                    width={150}
                  />
                  <Column
                    dataField="AMOUNTTWO"
                    caption="Owner 2"
                    format={"$###,###,###"}
                    alignment="right"
                    width={150}
                  />
                  <Column
                    dataField="TOTALAMOUNT"
                    caption="Total"
                    format={"$###,###,###"}
                    alignment="right"
                    width={150}
                  />
                  <Column
                    dataField="DONOTPRINT"
                    dataType="boolean"
                    caption="Do not Print "
                    visible={true}
                  />
                  <Column
                    dataField="CLIENTID"
                    caption="Client id"
                    visible={false}
                  />
                </DataGrid>
              </div>
            )}
            {loadForm && (
              <CFStructureResult
                clientCode={props.clientCode}
                ShowCreateClientPresentation={
                  props.ShowCreateClientPresentation
                }
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CFStructure;
