//// getInvestmentRecords

import React, { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import DateBox from "devextreme-react/date-box";
import TextBox from "devextreme-react/text-box";

import withReactContent from "sweetalert2-react-content";
import DropDownBox from "devextreme-react/drop-down-box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import DataGrid, {
  Column,
  Editing,
  Lookup,
  AsyncRule,
  Paging,
  Pager,
  HeaderFilter,
  FilterRow,
  SearchPanel,
} from "devextreme-react/data-grid";

import { RequiredRule } from "devextreme-react/validator";
import { Button } from "devextreme-react/button";

import "devextreme-react/text-area";
import "./app.scss";
import { useAuth } from "../../../contexts/auth";
import "devextreme/data/data_source";
import { ClientCodeMapping } from "./clientCodeMapping";
//import DataSource from "devextreme/data/data_source";
import {
  mystore6,
  getTransactionTypes,
  getBanks,
  validateImports,
  processImports,
  deleteImports,
  remapImports,
  fetchThisClientData,
  getInvestmentRecords,
  updateMapping,
} from "./segmentData";
import { set } from "date-fns";
//import { color } from "html2canvas/dist/types/css/types/color";
import html2canvas from "html2canvas";

const dropDownOptions = { width: 500 };
const ownerLabel = { "aria-label": "Owner" };

function ImportTransactionsx(props) {
  //const [dataSourcex, setDataSource] = useState(null);
  const [transTypes, setTransTypes] = useState(null);
  const [bankAccounts, setBankAccounts] = useState(null);
  const [clientCode, setClientCode] = useState(props.clientCode);
  const [transactionsReady, setTransactionsReady] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [startdate, setStartdate] = useState(null);
  const [enddate, setEnddate] = useState(null);
  const [investmentlist, setInvestmentList] = useState(null);
  const dataGridRef = useRef(null);
  const [ShowMappings, setShowMappings] = useState(false);
  const [allClients, setAllClients] = useState(false);

  const [totalEntries, setTotalEntries] = useState(0);
  const [errorEntries, setErrorEntries] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [errorCount, setErrorCount] = useState(0);

  const applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];
  const [showFilterRow, setShowFilterRow] = useState(true);
  const [currentFilter, setCurrentFilter] = useState(applyFilterTypes[0]);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);

  const [
    showProcessTransfersOnlyCheckbox,
    setShowProcessTransfersOnlyCheckbox,
  ] = useState(false);
  const [processTransfersOnly, setProcessTransfersOnly] = useState(false);

  const MySwal = withReactContent(Swal);

  const allowedPageSizes = [12, 24];

  const ProcessImports = async () => {
    console.log("transfers: ", processTransfersOnly);
    try {
      const data = await validateImports(
        clientCode,
        startdate,
        enddate,
        processTransfersOnly
      );
      if (data.valid === 1) {
        setTransactionsReady(true);
        //console.log("start", startdate, "end", enddate);
        await processImports(
          props.clientCode,
          startdate,
          enddate,
          processTransfersOnly
        ); // Wait for processing to complete
        // Show a success message using SweetAlert2
        await MySwal.fire({
          icon: "success",
          title: "Import Processed",
          text: "The import has been processed successfully.",
        });
        setRefreshKey((prevKey) => prevKey + 1);
        //setRefreshKey((prevKey) => prevKey + 1); // Update key after processing
      } else {
        // Show an error message using SweetAlert2
        await MySwal.fire({
          icon: "error",
          title: "Import Error",
          text: "There are errors in the import file. Please correct them.",
        });
      }
    } catch (error) {
      console.error("An error occurred during the import process:", error);
      // Optionally, handle any errors that might occur during the import process
    }
  };

  const handleProcessTransfersOnlyChange = (e) => {
    const isChecked = e.target.checked; // Access 'checked' property for checkboxes
    console.log("Checkbox changed, Process Transfers Only:", isChecked); // Verify the value
    setProcessTransfersOnly(isChecked); // Update state

    if (isChecked) {
      setDataSource((prevData) =>
        prevData.filter((item) => item.FPTRANSACTIONCODE === "998")
      );
    } else {
      // Reload all data if checkbox is unchecked
      const store = mystore6(props.clientCode);
      if (store && typeof store.load === "function") {
        store.load().then((data) => setDataSource(data));
      }
    }
  };

  const renderFPTransactionCell = (data) => {
    const { data: rowData } = data;

    let style = {};
    if (rowData.ERRORDESCRIPTION === "Transaction Type not found") {
      style = { backgroundColor: "lightblue" }; // Apply blue color
      const formattedTransactionAmount = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(rowData.TRANSACTIONAMOUNT);
      return <div style={style}>{formattedTransactionAmount}</div>;
    } else return <div style={style}>{rowData.TRANSACTIONAMOUNT}</div>;
  };

  const renderFPTransactionCellDate = (data) => {
    const { data: rowData } = data;

    let style = {};
    if (rowData.ERROR === 1) {
      style = { backgroundColor: "lightblue" }; // Apply red color for errors
    }

    // Assuming TRANSACTIONDATE is in a format that can be directly used to create a Date object
    const transactionDate = new Date(rowData.TRANSACTIONDATE);
    const formattedDate = transactionDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    return <div style={style}>{formattedDate}</div>;
  };

  const DeleteImportsButton = () => {
    deleteImports(props.clientCode).then(() => {
      // Show a success message using SweetAlert2
      MySwal.fire({
        icon: "success",
        title: "Imports Deleted",
        text: "The imports have been removed",
      });
      setRefreshKey((prevKey) => prevKey + 1);
    });
  };

  const validateSegment = async (params) => {
    if (!params.data.SEGMENTNUMBER) {
      return true;
    }
    return await asyncValidation(
      params.data.BANKACCOUNTNUMBER,
      clientCode,
      params.data.SEGMENTNUMBER
    );
  };

  const onInitNewRow = (e) => {};

  useEffect(() => {
    getTransactionTypes() // call the function to fetch data
      .then((data) => {
        setTransTypes(data.data); // store the data in state
      })
      .catch((error) => {
        console.error(
          "There was an error fetching the transaction Types data:",
          error
        );
      });
    getBanks(props.clientCode) // call the function to fetch data
      .then((data) => {
        setBankAccounts(data.data); // store the data in state
      })
      .catch((error) => {
        console.error(
          "There was an error fetching the transaction Types data:",
          error
        );
      });

    fetchThisClientData(props.clientCode) // call the function to fetch data
      .then((data) => {
        setStartdate(data.STARTDATE); // store the data in state
        setEnddate(data.ENDDATE); // store the data in state
        setBankAccounts(data.data); // store the data in state
      })
      .catch((error) => {
        console.error(
          "There was an error fetching the transaction Types data:",
          error
        );
      });
    getInvestmentRecords(props.clientCode) // call the function to fetch data
      .then((data) => {
        setInvestmentList(data.data); // store the data in state
      })
      .catch((error) => {
        console.error(
          "There was an error fetching the transaction Types data:",
          error
        );
      });
    setRefreshKey((prevKey) => prevKey + 1);
    //console.log("in refresh");
  }, []);

  const handleStartDateChange = (e) => {
    setStartdate(e.value);
    setRefreshKey((prevKey) => prevKey + 1);
  };

  const handleEndDateChange = (e) => {
    setEnddate(e.value);
    setRefreshKey((prevKey) => prevKey + 1);
  };
  const UpdateMappings = () => {
    setShowMappings(true);
  };

  const RefreshMappings = async () => {
    try {
      setIsLoading(true); // Start loading

      //console.log("Refreshing mappings for client code:", clientCode);

      await remapImports(clientCode);

      MySwal.fire({
        icon: "success",
        title: "Remapping Complete",
        text: "The remapping process has been successfully completed.",
      });

      setRefreshKey((prevKey) => prevKey + 1);
    } catch (error) {
      // Handle any errors that occur during the update or remap process
      console.error("An error occurred during the remapping process:", error);
      MySwal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred during the remapping process.",
      });
    }
    setIsLoading(false); // Stop loading
  };

  const handleMappingUpdated = (value) => {
    setShowMappings(false);
    // Do something with the value, like updating the state
  };

  // const ClearableEditCell = (props) => {
  //   const { data, setValue } = props;
  //   const handleClear = () => setValue(undefined); // Or '' if your data structure expects a string

  //   return (
  //     <div>
  //       <TextBox value={data.value} onValueChanged={(e) => setValue(e.value)} />
  //       <Button
  //         icon="clear" // Use an appropriate icon
  //         onClick={handleClear}
  //       />
  //     </div>
  //   );
  // };

  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    console.log("shared value: ", props.sharedValue);
    const loadData = async () => {
      try {
        setIsLoading(true);
        console.log("Fetching data for client code:", props.clientCode);

        // Access the CustomStore and then call the `load` method
        const store = mystore6(props.clientCode);
        if (store && typeof store.load === "function") {
          const data = await store.load();
          setDataSource(data);

          // Check if any row has FPTRANSACTIONCODE '998'
          const hasTransferCode998 = data.some(
            (record) => record.FPTRANSACTIONCODE === "998"
          );

          setShowProcessTransfersOnlyCheckbox(hasTransferCode998);
          console.log("998 in file: '", hasTransferCode998);

          const errorRecordsCount = data.filter(
            (record) => record.ERROR === 1
          ).length;
          setErrorCount(errorRecordsCount);
          console.log("Number of records with ERROR=1: ", errorRecordsCount);
        } else {
          console.error(
            "mystore6 didn't return a CustomStore with a load function."
          );
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [props.clientCode, refreshKey, props.sharedValue]); // Dependency on clientCode and refreshKey

  const onRowUpdated = async (e) => {
    try {
      setIsLoading(true);
      const updateData = e.data;
      const key = e.key;

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          ThisFunction: "change",
          sentcompany: e.data.UNIQUEID,
          keyvaluepair: updateData,
        }),
      };

      const url = `${process.env.REACT_APP_BASE_URL}/UpdateImportClientTransactions`;
      const response = await fetch(url, requestOptions);

      if (!response.ok) throw new Error("Failed to update the database");

      const result = await response.json();
      console.log("status", result.user_response.status);

      if (result && result.user_response.status === 200) {
        console.log("returned from update", result.user_response);
        // Update dataSource directly without refreshing the entire DataGrid
        setDataSource((prevData) =>
          prevData.map((item) =>
            item.UNIQUEID === key
              ? { ...item, ...updateData, ...result.user_response.keyvaluepair }
              : item
          )
        );
        const hasTransferCode998 = dataSource.some(
          (record) => record.FPTRANSACTIONCODE === "998"
        );
        setShowProcessTransfersOnlyCheckbox(hasTransferCode998);
      } else {
        MySwal.fire({
          icon: "error",
          title: "Update Failed",
          text: "The data update failed: " + (result.error || "Unknown error"),
        });
      }
    } catch (error) {
      console.error("Update error:", error);
      MySwal.fire({
        icon: "error",
        title: "Update Error",
        text: "An error occurred while updating the data: " + error.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onRowInserted = async (e) => {
    setIsLoading(true);
    try {
      console.log("Inserting row with data:", e.data);

      // Prepare the request similar to the update logic
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          ThisFunction: "insert",
          sentcompany: clientCode,
          keyvaluepair: e.data,
        }),
      };

      const url = `${process.env.REACT_APP_BASE_URL}/UpdateImportClientTransactions`;
      const response = await fetch(url, requestOptions);

      if (!response.ok)
        throw new Error("Failed to insert new data into the database");

      const result = await response.json();
      console.log("Insert result:", result);

      if (
        result &&
        result.user_response &&
        result.user_response.status === 200
      ) {
        // MySwal.fire({
        //   icon: "success",
        //   title: "Insert Successful",
        //   text: "A new record has been added successfully.",
        // });

        // Check if you need to manually update dataSource
        // Only update if the grid does not auto-refresh

        setRefreshKey((prevKey) => prevKey + 1);

        if (!result.user_response.autoRefresh) {
          //   const newRecord = { ...e.data, ...result.user_response.keyvaluepair };
          //   if (
          //     !dataSource.some((item) => item.UNIQUEID === newRecord.UNIQUEID)
          //   ) {
          //     setDataSource((prevData) => [...prevData, newRecord]);
          //   }
        }
      } else {
        throw new Error(
          "The data insert failed: " + (result.error || "Unknown error")
        );
      }
    } catch (error) {
      console.error("Insert error:", error);
      MySwal.fire({
        icon: "error",
        title: "Insert Failed",
        text: "Failed to add new record: " + error.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onRowInserting = (e) => {
    console.log("Inserting a row", e);
  };

  const onRowUpdating = (e) => {
    console.log("Updating a row", e);
  };

  const onRowRemoving = (e) => {
    console.log("Removing a row", e); // Check if this gets logged when you try to delete a row
  };

  const onRowRemoved = async (e) => {
    console.log("Attempting to delete row with data:", e.data); // Check if this line logs when you delete a row
    setIsLoading(true);
    try {
      const key = e.key;
      console.log("Deleting record with UNIQUEID:", key);

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          ThisFunction: "delete",
          sentcompany: key, // clientCode,
          keyvaluepair: { UNIQUEID: key },
        }),
      };

      const url = `${process.env.REACT_APP_BASE_URL}/UpdateImportClientTransactions`;
      const response = await fetch(url, requestOptions);

      if (!response.ok)
        throw new Error("Failed to delete the record from the database");

      const result = await response.json();
      console.log("Delete result:", result);

      if (
        result &&
        result.user_response &&
        result.user_response.status === 200
      ) {
        // MySwal.fire({
        //   icon: "success",
        //   title: "Delete Successful",
        //   text: "The record has been deleted successfully.",
        // });

        const newData = dataSource.filter((item) => item.UNIQUEID !== key);
        setDataSource(newData);
      } else {
        throw new Error(
          "The record delete failed: " + (result.error || "Unknown error")
        );
      }
    } catch (error) {
      console.error("Delete error:", error);
      MySwal.fire({
        icon: "error",
        title: "Delete Failed",
        text: "Failed to delete the record: " + error.message,
      });
    } finally {
      setIsLoading(false);
    }
    console.log("Removed a row", e);
  };

  // Enhanced onRowDeleted function with debug logs
  const onRowDeleted = async (e) => {
    console.log("Attempting to delete row with data:", e.data); // Check if this line logs when you delete a row
    setIsLoading(true);
    try {
      const key = e.key;
      console.log("Deleting record with UNIQUEID:", key);

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          ThisFunction: "delete",
          sentcompany: clientCode,
          keyvaluepair: { UNIQUEID: key },
        }),
      };

      const url = `${process.env.REACT_APP_BASE_URL}/UpdateImportClientTransactions`;
      const response = await fetch(url, requestOptions);

      if (!response.ok)
        throw new Error("Failed to delete the record from the database");

      const result = await response.json();
      console.log("Delete result:", result);

      if (
        result &&
        result.user_response &&
        result.user_response.status === 200
      ) {
        MySwal.fire({
          icon: "success",
          title: "Delete Successful",
          text: "The record has been deleted successfully.",
        });

        const newData = dataSource.filter((item) => item.UNIQUEID !== key);
        setDataSource(newData);
      } else {
        throw new Error(
          "The record delete failed: " + (result.error || "Unknown error")
        );
      }
    } catch (error) {
      console.error("Delete error:", error);
      MySwal.fire({
        icon: "error",
        title: "Delete Failed",
        text: "Failed to delete the record: " + error.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // const countRows = () => {
  //   const gridData = dataGridRef.current.instance.getDataSource().items();
  //   //console.log("grid", gridData);
  //   const total = gridData.length;
  //   const errorCount = gridData.filter((row) => row.ERROR === 1).length;

  //   setTotalEntries(total);
  //   setErrorEntries(errorCount);
  //   //console.log("total", total, "errors:", errorCount);
  // };

  // useEffect(() => {
  //   // Assuming you want to count rows whenever the grid is refreshed
  //   // You can call countRows here or in response to some grid event
  //   countRows();
  // }, [refreshKey]); // Dependency on refreshKey if needed

  return (
    <>
      {showProcessTransfersOnlyCheckbox && (
        <div style={{ marginBottom: "10px" }}>
          <label style={{ color: "red" }}>
            {" "}
            {/* Move the style directly to the label */}
            <input
              type="checkbox"
              checked={processTransfersOnly}
              onChange={handleProcessTransfersOnlyChange} // Use onChange, not onClick
            />
            Process Transfers Only
          </label>
        </div>
      )}
      {isLoading && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "50vh",
          }}
        >
          <p style={{ marginBottom: "10px", fontSize: "24px" }}>
            Processing, please wait...
          </p>
          <FontAwesomeIcon icon={faSpinner} spin style={{ fontSize: "48px" }} />
        </div>
      )}
      <div>
        {errorCount > 0 && (
          <p style={{ color: "red", fontWeight: "bold" }}>
            # of records (original) with errors: {errorCount}
          </p>
        )}
      </div>
      {ShowMappings !== true && (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              text="Process Imports"
              onClick={ProcessImports}
              style={{ marginRight: "30px" }} // Add right margin to the button
            />
            <Button
              text="Delete All Imports"
              onClick={DeleteImportsButton}
              style={{ marginRight: "30px" }} // Add right margin to the button
            />

            <div style={{ marginRight: "40px" }}>
              <label>
                Start Date (MM/DD/YYYY):
                <DateBox
                  type="date"
                  value={startdate}
                  onValueChanged={handleStartDateChange}
                />
              </label>
            </div>
            <div>
              <label>
                End Date (MM/DD/YYYY):
                <DateBox
                  type="date"
                  value={enddate}
                  onValueChanged={handleEndDateChange}
                />
              </label>
            </div>
            <div
              style={{
                backgroundColor: "lightblue",
                marginTop: "10px",
                marginLeft: "10px",
              }}
            >
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Amounts in blue indicate an
                invalid transaction type&nbsp;&nbsp;
              </p>
            </div>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;</p>
            <Button
              text="Update Mappings"
              onClick={UpdateMappings}
              style={{ marginRight: "30px" }} // Add right margin to the button
            />
            <Button
              text="Refresh Mappings"
              onClick={RefreshMappings}
              style={{ marginRight: "30px" }} // Add right margin to the button
            />
          </div>
          <div className="red-color">
            <div className="custom-container" style={{ height: "800px" }}>
              <DataGrid
                elementAttr={{ class: "custom-data-grid" }} // Add custom class
                dataSource={dataSource}
                onInitNewRow={onInitNewRow}
                keyExpr="UNIQUEID"
                remoteOperations={true}
                onRowInserting={onRowInserting}
                onRowUpdating={onRowUpdating}
                onRowRemoving={onRowRemoving}
                onRowRemoved={onRowRemoved}
                onRowInserted={onRowInserted}
                onRowUpdated={onRowUpdated}
                onRowDeleted={onRowDeleted}
                showBorders={true}
                paging={{ enabled: false }} // Disables paging
                scrolling={{ mode: "standard" }} // Ensures smooth scrolling
                height="auto" // Adjust height dynamically based on content
                // ref={dataGridRef}
                //key={refreshKey} // This key will force a refresh when it changes
                //dataSource={mystore6(props.clientCode)}
              >
                {/* <Paging enabled={true} /> */}
                <FilterRow
                  visible={showFilterRow}
                  applyFilter={currentFilter}
                />
                <HeaderFilter visible={showHeaderFilter} />
                <SearchPanel
                  visible={false}
                  width={240}
                  placeholder="Search..."
                />

                {/* <Sorting mode="single" />

                <FilterRow visible />
                <HeaderFilter visible /> */}

                <Editing
                  mode="cell"
                  allowUpdating={true}
                  allowAdding={true}
                  allowDeleting={true}
                  //selectionMode="single"
                ></Editing>
                <Column
                  dataType="boolean"
                  dataField={"ERROR"}
                  caption={"Error"}
                  allowEditing={false}
                  width={80}
                ></Column>
                <Column
                  dataField={"BANKACCOUNTNUMBER"}
                  caption="Bank Account"
                  allowEditing={true}
                  width={200}
                >
                  <Lookup
                    dataSource={bankAccounts}
                    valueExpr="BANKACCOUNTNUMBER"
                    //displayExpr="BANKACCOUNTNUMBER"
                    displayExpr={(item) =>
                      item
                        ? `${item.BANKNAME} - ${item.BANKACCOUNTNUMBER} - ${item.ACCOUNTDESCRIPTION}`
                        : ""
                    }
                  />
                </Column>

                <Column
                  width={50}
                  dataField={"SEGMENTNUMBER"}
                  caption={"Seg"}
                  allowEditing={true}
                >
                  <AsyncRule
                    message="Segment Does Not Exists"
                    validationCallback={validateSegment}
                  />
                </Column>
                <Column
                  dataType="date"
                  dataField="TRANSACTIONDATE"
                  caption="Date"
                  allowEditing={true}
                  cellRender={renderFPTransactionCellDate}
                  width={100}
                />
                {/* <Column
                  dataType="boolean"
                  dataField={"DATEERROR"}
                  caption="Date Issue"
                  allowEditing={false}
                /> */}

                {/* <RequiredRule message="A Date is required" /> */}

                <Column
                  dataField={"DESCRIPTION"}
                  caption="Bank Description"
                  allowEditing={true}
                  width={300}
                />
                <Column
                  dataField={"SECONDDESCRIPTION"}
                  caption="Details"
                  allowEditing={true}
                  width={300}
                />
                <Column
                  dataField={"FPTRANSACTIONCODE"}
                  caption={"Type"}
                  allowEditing={true}
                  width={200}
                >
                  <RequiredRule message="A Transaction Code is required" />
                  <Lookup
                    allowClearing={true}
                    dataSource={transTypes}
                    valueExpr="FPTRANSACTIONCODE"
                    displayExpr="LONGDESCRIPTION"
                  />
                </Column>
                <Column
                  dataField={"INVESTMENTID"}
                  caption="id"
                  allowEditing={true}
                  width={300}

                  //editCellComponent={PrepareInvestmentID}
                >
                  <Lookup
                    dataSource={investmentlist}
                    valueExpr="UNIQUEID"
                    //displayExpr="INVESTMENTNAME"
                    displayExpr={(item) =>
                      item
                        ? `${item.INVESTMENTNAME} - ${item.INVESTMENTSUBGROUP}  ${item.INVESTMENTTYPE}`
                        : ""
                    }
                    //allowClearing={true}
                  />
                </Column>

                <Column
                  dataField={"TRANSACTIONAMOUNT"}
                  caption={"Amount"}
                  allowEditing={true}
                  format="$###,###,###.00"
                  width={150}
                  cellRender={renderFPTransactionCell}
                />
                <Column
                  dataField={"CLEARID"}
                  caption={"CLR"}
                  allowEditing={true}
                  dataType="boolean"
                  width={100}
                />

                <Column
                  dataType="boolean"
                  dataField={"INTERESTCHARGE"}
                  caption="Int Chge"
                  allowEditing={false}
                  visible={false}
                />
                <Column
                  dataType="boolean"
                  dataField={"INTERESTCOST"}
                  caption="Int"
                  allowEditing={true}
                />

                <Column
                  dataField={"UNIQUEID"}
                  caption={"ID"}
                  allowEditing={true}
                  visible={false}
                />
                {/* <Paging defaultPageSize={24} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={allowedPageSizes}
                /> */}
              </DataGrid>
            </div>
          </div>
        </>
      )}
      <div>
        {ShowMappings && (
          <div className="overlay">
            <ClientCodeMapping
              clientCode={clientCode}
              onMappingUpdated={handleMappingUpdated}
            />
          </div>
        )}
      </div>
    </>
  );
}

async function asyncValidation(bankAccountNumber, clientCode, segmentNumber) {
  var requestoptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json;",
    },
    body: JSON.stringify({
      sentBankAccountNumber: bankAccountNumber,
      sentSegment: segmentNumber,
      sentClientCode: clientCode,
    }),
  };
  const url = `${process.env.REACT_APP_BASE_URL}/fetchThisClientSegment`;

  const response = await fetch(url, requestoptions);
  if (!response.ok) {
    throw new Error("System did not respond");
  }
  const data = await response.json();
  if (data.user_response.response === "ERROR") {
    return true; // Validation successful
  } else if (data.user_response.response === "OK") {
    return false; // Validation failed
  } else {
    throw new Error("Unexpected response");
  }
}

//export default ClientTransactions;

export default function ImportTransactions(props) {
  console.log("props are: ", props);
  const { user } = useAuth();
  return (
    <ImportTransactionsx
      clientCode={user.thisClientcode}
      sharedValue={props.sharedValue}
    />
  );
}
