import React, { useState } from "react";
import CustomStore from "devextreme/data/custom_store";
function isNotEmpty(value) {
  return value !== undefined && value !== null && value !== "";
}

export const CFIncomeData = (myClient, statementtype) =>
  new CustomStore({
    key: "UNIQUEID",
    load: (loadOptions) => {
      let params = "?";
      [
        "skip",
        "take",
        "requireTotalCount",
        "requireGroupCount",
        "sort",
        "filter",
        "totalSummary",
        "group",
        "groupSummary",
      ].forEach((i) => {
        if (i in loadOptions && isNotEmpty(loadOptions[i])) {
          params += `${i}=${JSON.stringify(loadOptions[i])}&`;
        }
      });

      params = params.slice(0, -1);
      var requestoptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json;",
        },
        body: JSON.stringify({
          sentclientcode: myClient,
          statementtype: statementtype,
          Parameters: params,
        }),
      };
      const url = `${process.env.REACT_APP_BASE_URL}/getCFIncomeData2`;
      return fetch(url, requestoptions) // Request fish
        .then((response) => {
          ////console.log("client " + myClient);
          if (!response.ok) {
            return {
              companyname: "System did not respond",
              returnaddress: " ",
            };
          }
          return response.json();
        })
        .then((json) => {
          console.log("from client: ", myClient, "assets go bang: ", json);
          return {
            data: json.user_response.bankq,
            totalCount: json.user_response.totalCount,
            key: json.user_response.keyname,
          };
        });
    },
    insert: (values) => {
      var requestoptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json;",
        },
        body: JSON.stringify({
          ThisFunction: "insert",
          keyvaluepair: values,
          sentcompany: myClient,
        }),
      };
      const url = `${process.env.REACT_APP_BASE_URL}/updateCFIncomeData`;
      return fetch(url, requestoptions) // Request fish
        .then((response) => {
          if (!response.ok) {
            return {
              companyname: "System did not respond",
              returnaddress: " ",
            };
          }
          return response.json();
        })
        .then((json) => {
          return {};
        });
    },
    remove: (key) => {
      //console.log(key);
      ////console.log(values);
      var requestoptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json;",
        },
        body: JSON.stringify({
          sentcompany: key,
          ThisFunction: "delete",
        }),
      };
      const url = `${process.env.REACT_APP_BASE_URL}/updateCFIncomeData`;
      return fetch(url, requestoptions) // Request fish
        .then((response) => {
          if (!response.ok) {
            return {
              companyname: "System did not respond",
              returnaddress: " ",
            };
          }
          return response.json();
        })
        .then((json) => {
          return {};
        });
    },
    update: (key, values) => {
      console.log("key: ", key);
      console.log("values: ", values);
      var requestoptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json;",
        },
        body: JSON.stringify({
          ThisFunction: "change",
          sentcompany: key,
          keyvaluepair: values,
        }),
      };
      //console.log("key: ', key", "values", values);
      const url = `${process.env.REACT_APP_BASE_URL}/updateCFIncomeData`;
      return fetch(url, requestoptions) // Request fish
        .then((response) => {
          if (!response.ok) {
            return {
              companyname: "System did not respond",
              returnaddress: " ",
            };
          }
          return response.json();
        })
        .then((json) => {
          return {};
        });
    },
  });

export const mystore11 = (myClient) => {
  //var myClient = 1;
  var requestoptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json;",
    },
    body: JSON.stringify({
      sentclientcode: myClient,
    }),
  };
  const url = `${process.env.REACT_APP_BASE_URL}/getClientOwners`;
  return fetch(url, requestoptions) // Request fish
    .then((response) => {
      ////console.log("client " + myClient);
      if (!response.ok) {
        return {
          companyname: "System did not respond",
          returnaddress: " ",
        };
      }
      return response.json();
    })
    .then((json) => {
      //console.log("Owners: ", json);
      return {
        data: json.user_response.bankq,
      };
    });
};

///getCFIncomeTypesData

export const getCFIncomeTypesDataFilter = (IncomeStatementType) => {
  console.log("IncomeStatementType: ", IncomeStatementType);
  //var myClient = 1;
  var requestoptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json;",
    },
    body: JSON.stringify({
      IncomeStatementType: IncomeStatementType,
      //   sentclientcode: myClient,
    }),
  };
  const url = `${process.env.REACT_APP_BASE_URL}/getCFIncomeTypesDataFilter`;
  return fetch(url, requestoptions) // Request fish
    .then((response) => {
      ////console.log("client " + myClient);
      if (!response.ok) {
        return {
          companyname: "System did not respond",
          returnaddress: " ",
        };
      }
      return response.json();
    })
    .then((json) => {
      console.log("getCFIncomeTypesDataFilter types list", json);
      return {
        data: json.user_response.bankq,
      };
    });
};

export const getCFIncomeTypesData = () => {
  //console.log("IncomeStatementType: ", IncomeStatementType);
  //var myClient = 1;
  var requestoptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json;",
    },
    body: JSON.stringify({
      //IncomeStatementType: IncomeStatementType,
      //   sentclientcode: myClient,
    }),
  };
  const url = `${process.env.REACT_APP_BASE_URL}/getCFIncomeTypesData`;
  return fetch(url, requestoptions) // Request fish
    .then((response) => {
      ////console.log("client " + myClient);
      if (!response.ok) {
        return {
          companyname: "System did not respond",
          returnaddress: " ",
        };
      }
      return response.json();
    })
    .then((json) => {
      console.log("types list", json);
      return {
        data: json.user_response.bankq,
      };
    });
};

export const CFTaxData = (myClient) =>
  new CustomStore({
    key: "UNIQUEID",
    load: (loadOptions) => {
      let params = "?";
      [
        "skip",
        "take",
        "requireTotalCount",
        "requireGroupCount",
        "sort",
        "filter",
        "totalSummary",
        "group",
        "groupSummary",
      ].forEach((i) => {
        if (i in loadOptions && isNotEmpty(loadOptions[i])) {
          params += `${i}=${JSON.stringify(loadOptions[i])}&`;
        }
      });

      params = params.slice(0, -1);
      var requestoptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json;",
        },
        body: JSON.stringify({
          sentclientcode: myClient,
          Parameters: params,
        }),
      };
      const url = `${process.env.REACT_APP_BASE_URL}/getCFTaxData`;
      return fetch(url, requestoptions) // Request fish
        .then((response) => {
          ////console.log("client " + myClient);
          if (!response.ok) {
            return {
              companyname: "System did not respond",
              returnaddress: " ",
            };
          }
          return response.json();
        })
        .then((json) => {
          //console.log("from client: ", myClient, "assets go bang: ", json);
          return {
            data: json.user_response.bankq,
            totalCount: json.user_response.totalCount,
            key: json.user_response.keyname,
          };
        });
    },
    insert: (values) => {
      var requestoptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json;",
        },
        body: JSON.stringify({
          ThisFunction: "insert",
          keyvaluepair: values,
          sentcompany: myClient,
        }),
      };
      const url = `${process.env.REACT_APP_BASE_URL}/updateCFTaxData`;
      return fetch(url, requestoptions) // Request fish
        .then((response) => {
          if (!response.ok) {
            return {
              companyname: "System did not respond",
              returnaddress: " ",
            };
          }
          return response.json();
        })
        .then((json) => {
          return {};
        });
    },
    remove: (key) => {
      //console.log(key);
      ////console.log(values);
      var requestoptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json;",
        },
        body: JSON.stringify({
          sentcompany: key,
          ThisFunction: "delete",
        }),
      };
      const url = `${process.env.REACT_APP_BASE_URL}/updateCFTaxData`;
      return fetch(url, requestoptions) // Request fish
        .then((response) => {
          if (!response.ok) {
            return {
              companyname: "System did not respond",
              returnaddress: " ",
            };
          }
          return response.json();
        })
        .then((json) => {
          return {};
        });
    },
    update: (key, values) => {
      console.log("key: ", key);
      console.log("values: ", values);
      var requestoptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json;",
        },
        body: JSON.stringify({
          ThisFunction: "change",
          sentcompany: key,
          keyvaluepair: values,
        }),
      };
      //console.log("key: ', key", "values", values);
      const url = `${process.env.REACT_APP_BASE_URL}/updateCFTaxData`;
      return fetch(url, requestoptions) // Request fish
        .then((response) => {
          if (!response.ok) {
            return {
              companyname: "System did not respond",
              returnaddress: " ",
            };
          }
          return response.json();
        })
        .then((json) => {
          return {};
        });
    },
  });

export const IncomeSummaryData = async (myClient, statementtype) => {
  const requestoptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json;",
    },
    body: JSON.stringify({
      sentclientcode: myClient,
      statementtype: statementtype,
      skip: 1,
    }),
  };
  const url = `${process.env.REACT_APP_BASE_URL}/getCFIncomeSummaryData2`;
  const response = await fetch(url, requestoptions);
  if (!response.ok) {
    return {
      data: [],
      error: "System did not respond",
    };
  }
  const json = await response.json();
  console.log("cash flow list", json.user_response.LineQ);
  return {
    data: json.user_response.LineQ,
  };
};

export const TaxSummaryData = async (myClient) => {
  const requestoptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json;",
    },
    body: JSON.stringify({
      sentclientcode: myClient,
    }),
  };
  const url = `${process.env.REACT_APP_BASE_URL}/getCFTaxSummaryData2`;
  const response = await fetch(url, requestoptions);
  if (!response.ok) {
    return {
      data: [],
      error: "System did not respond",
    };
  }
  const json = await response.json();
  console.log("types list", json.user_response.LineQ);
  return {
    data: json.user_response.LineQ,
  };
};

export const StructureSummaryData = async (myClient) => {
  const requestoptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json;",
    },
    body: JSON.stringify({
      sentclientcode: myClient,
    }),
  };
  const url = `${process.env.REACT_APP_BASE_URL}/getCFStructureSummaryData2`;
  const response = await fetch(url, requestoptions);
  if (!response.ok) {
    return {
      data: [],
      error: "System did not respond",
    };
  }
  const json = await response.json();
  console.log("types list", json.user_response.LineQ);
  return {
    data: json.user_response.LineQ,
  };
};

export const copycustomerStructure = async (
  thiscustomer,
  currentClientCode
) => {
  const requestoptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json;",
    },
    body: JSON.stringify({
      sentclientcode: thiscustomer,
      copycustomercode: currentClientCode,
    }),
  };
  console.log(
    "thiscustomer",
    thiscustomer,
    "currentClientCode",
    currentClientCode
  );
  const url = `${process.env.REACT_APP_BASE_URL}/copycustomerStructure`;
  const response = await fetch(url, requestoptions);
  if (!response.ok) {
    return {
      data: [],
      error: "System did not respond",
    };
  }
  const json = await response.json();
  console.log("types list", json.user_response.LineQ);
  return {
    data: json.user_response.LineQ,
  };
};

export const ResequenceIncomeData = async (
  currentClientCode,
  IncomeStatementType
) => {
  const requestoptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json;",
    },
    body: JSON.stringify({
      sentclientcode: currentClientCode,
      IncomeStatementType: IncomeStatementType,
    }),
  };
  // console.log(
  //   "thiscustomer",
  //   thiscustomer,
  //   "currentClientCode",
  //   currentClientCode
  // );
  const url = `${process.env.REACT_APP_BASE_URL}/ResequenceIncomeData`;
  const response = await fetch(url, requestoptions);
  if (!response.ok) {
    return {
      data: [],
      error: "System did not respond",
    };
  }
  const json = await response.json();
  console.log("types list", json.user_response.bankQ);
  return {
    data: json.user_response.LineQ,
  };
};

export const getClientIncomeStructure = async (myClient) => {
  const requestoptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json;",
    },
    body: JSON.stringify({
      sentclientcode: myClient,
    }),
  };
  const url = `${process.env.REACT_APP_BASE_URL}/getClientIncomeStructure`;
  const response = await fetch(url, requestoptions);
  if (!response.ok) {
    return {
      data: [],
      error: "System did not respond",
    };
  }
  const json = await response.json();
  console.log("getClientIncomeStructure types list", json.user_response.bankq);
  return {
    data: json.user_response.LineQ,
  };
};

export const getStatementReportGroups = async (myClient) => {
  const requestoptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json;",
    },
    body: JSON.stringify({
      sentclientcode: myClient,
    }),
  };
  const url = `${process.env.REACT_APP_BASE_URL}/getCFReportGroupData`;
  const response = await fetch(url, requestoptions);
  if (!response.ok) {
    return {
      data: [],
      error: "System did not respond",
    };
  }
  const json = await response.json();
  console.log("getStatementReportGroups types list", json.user_response.bankq);
  return {
    data: json.user_response.bankq,
  };
};

export const CFIncomeData2 = (myClient, statementtype) =>
  new CustomStore({
    key: "UNIQUEID",
    load: (loadOptions) => {
      let params = "?";
      [
        "skip",
        "take",
        "requireTotalCount",
        "requireGroupCount",
        "sort",
        "filter",
        "totalSummary",
        "group",
        "groupSummary",
      ].forEach((i) => {
        if (i in loadOptions && isNotEmpty(loadOptions[i])) {
          params += `${i}=${JSON.stringify(loadOptions[i])}&`;
        }
      });

      params = params.slice(0, -1);
      var requestoptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json;",
        },
        body: JSON.stringify({
          sentclientcode: myClient,
          statementtype: statementtype,
          Parameters: params,
        }),
      };
      const url = `${process.env.REACT_APP_BASE_URL}/getCFIncomeData2`;
      return fetch(url, requestoptions) // Request fish
        .then((response) => {
          ////console.log("client " + myClient);
          if (!response.ok) {
            return {
              companyname: "System did not respond",
              returnaddress: " ",
            };
          }
          return response.json();
        })
        .then((json) => {
          console.log("from client: ", myClient, "assets go bang: ", json);
          return {
            data: json.user_response.bankq,
            totalCount: json.user_response.totalCount,
            key: json.user_response.keyname,
          };
        });
    },
    insert: (values) => {
      var requestoptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json;",
        },
        body: JSON.stringify({
          ThisFunction: "insert",
          keyvaluepair: values,
          sentcompany: myClient,
        }),
      };
      const url = `${process.env.REACT_APP_BASE_URL}/updateCFIncomeData`;
      return fetch(url, requestoptions) // Request fish
        .then((response) => {
          if (!response.ok) {
            return {
              companyname: "System did not respond",
              returnaddress: " ",
            };
          }
          return response.json();
        })
        .then((json) => {
          return {};
        });
    },
    remove: (key) => {
      //console.log(key);
      ////console.log(values);
      var requestoptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json;",
        },
        body: JSON.stringify({
          sentcompany: key,
          ThisFunction: "delete",
        }),
      };
      const url = `${process.env.REACT_APP_BASE_URL}/updateCFIncomeData`;
      return fetch(url, requestoptions) // Request fish
        .then((response) => {
          if (!response.ok) {
            return {
              companyname: "System did not respond",
              returnaddress: " ",
            };
          }
          return response.json();
        })
        .then((json) => {
          return {};
        });
    },
    update: (key, values) => {
      console.log("key: ", key);
      console.log("values: ", values);
      var requestoptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json;",
        },
        body: JSON.stringify({
          ThisFunction: "change",
          sentcompany: key,
          keyvaluepair: values,
        }),
      };
      //console.log("key: ', key", "values", values);
      const url = `${process.env.REACT_APP_BASE_URL}/updateCFIncomeData`;
      return fetch(url, requestoptions) // Request fish
        .then((response) => {
          if (!response.ok) {
            return {
              companyname: "System did not respond",
              returnaddress: " ",
            };
          }
          return response.json();
        })
        .then((json) => {
          return {};
        });
    },
  });

export const UpdateStructureData = async (myClient, statementtype) => {
  const requestoptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json;",
    },
    body: JSON.stringify({
      sentclientcode: myClient,
      statementtype: statementtype,
    }),
  };
  const url = `${process.env.REACT_APP_BASE_URL}/UpdateStructureData`;
  const response = await fetch(url, requestoptions);
  if (!response.ok) {
    return {
      data: [],
      error: "System did not respond",
    };
  }
  const json = await response.json();
  console.log("getStatementReportGroups types list", json.user_response.bankq);
  return {
    data: json.user_response.bankq,
  };
};
