import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Form, Item, EmptyItem } from "devextreme-react/form";
import { TabPanel, Item as TabItem } from "devextreme-react/tab-panel";

import DataGrid, {
  Column,
  Paging,
  FilterRow,
  HeaderFilter,
  Editing,
  Lookup,
} from "devextreme-react/data-grid";

import {
  CFIncomeData2,
  mystore11,
  getCFIncomeTypesData,
  ResequenceIncomeData,
  getStatementReportGroups,
  getCFIncomeTypesDataFilter,
} from "./cfData";
import CFIncomeResult from "./CFCashFlowResult";
import SelectBox from "devextreme-react/select-box";
import { get } from "react-hook-form";

//>>import "devextreme/dist/css/dx.light.css";
//>>import "./styles.css"; // Import the custom CSS file

const CFEnterStructure = (props) => {
  console.log("CFEnterStructure props: ", props);
  const [thisCustomer, setThisCustomer] = useState(props.clientCode); // selected customer
  const [IncomeStatementType, setIncomeStatementType] = useState("");
  const [StatementTypes, setStatementTypes] = useState([]);
  const [StatementReportTypes, setStatementReportTypes] = useState([]);
  const [resetKey, setResetKey] = useState(0);

  //console.log("client: ", props.clientCode);

  useEffect(() => {
    getStatementReportGroups() // cfreport group INC TX ST
      .then((data) => {
        setStatementTypes(data.data);
        setResetKey((prevKey) => prevKey + 1);
      })
      .catch((error) => {
        console.error(
          "There was an error fetching the transaction group data:",
          error
        );
      });
    getCFIncomeTypesDataFilter(IncomeStatementType) //cfstatementtypes gross income casjh inflow
      .then((data) => {
        setStatementReportTypes(data.data);
        setResetKey((prevKey) => prevKey + 1);
      })
      .catch((error) => {
        console.error(
          "There was an error fetching the transaction group data:",
          error
        );
      });
  }, [IncomeStatementType]);

  const calculateTotalAmount = (amountOne, amountTwo) => {
    const amt1 = parseFloat(amountOne) || 0;
    const amt2 = parseFloat(amountTwo) || 0;
    return amt1 + amt2;
  };

  const handleInitNewRow = (e) => {
    if (IncomeStatementType && IncomeStatementType.trim() !== "") {
      e.data.REPORTTYPE = IncomeStatementType;
    }
  };

  const dataSource = useMemo(
    () => CFIncomeData2(props.clientCode, IncomeStatementType),
    [props.clientCode, IncomeStatementType]
  );

  // useEffect(() => {
  //   StatementReportTypes()
  //     .then((data) => {
  //       setStatementReportTypes(data.data);
  //     })
  //     .catch((error) => {
  //       console.error(
  //         "There was an error fetching the transaction group data:",
  //         error
  //       );
  //     });
  // }, [IncomeStatementType]);

  return (
    <>
      <p> </p>
      <SelectBox
        className="white-text-selectbox"
        style={{ width: "200px", height: "40px", marginRight: "10px" }} // Adjusted to include marginRight
        items={StatementTypes}
        valueExpr="REPORTGROUP"
        displayExpr="DESCRIPTION"
        value={IncomeStatementType}
        searchEnabled={true}
        onValueChanged={(e) => setIncomeStatementType(e.value)} // Correctly handle the event
      />

      {IncomeStatementType && (
        <div>
          <DataGrid
            key={resetKey}
            dataSource={dataSource}
            keyExpr="UNIQUEID"
            showBorders={true}
            remoteOperations={false}
            //dataSource={CFIncomeData(props.clientCode, IncomeStatementType)}
            //keyExpr="UNIQUEID"
            //showBorders={true}
            //remoteOperations={false}
            onInitNewRow={handleInitNewRow}
            onEditorPreparing={(e) => {
              if (e.dataField === "AMOUNTONE" || e.dataField === "AMOUNTTWO") {
                e.editorOptions.onValueChanged = (args) => {
                  e.setValue(args.value);
                  const data = e.row && e.row.data;
                  if (data) {
                    const newAmountOne =
                      e.dataField === "AMOUNTONE" ? args.value : data.AMOUNTONE;
                    const newAmountTwo =
                      e.dataField === "AMOUNTTWO" ? args.value : data.AMOUNTTWO;
                    const totalAmount = calculateTotalAmount(
                      newAmountOne,
                      newAmountTwo
                    );
                    e.component.cellValue(
                      e.row.rowIndex,
                      "TOTALAMOUNT",
                      totalAmount
                    );
                  }
                };
              }
            }}
          >
            <Editing
              mode="cell"
              allowUpdating={true}
              allowAdding={true}
              allowDeleting={true}
            />
            <Paging enabled={false} />
            {/* <Column dataField="UNIQUEID" caption="Unique ID" visible={false} /> */}
            {/* <Column
              dataField="REPORTTYPE"
              caption="Report Type"
              visible={false}
            /> */}
            {/* <Column dataField="CLIENTCODE" caption="Client" visible={false} /> */}
            <Column
              dataField="TYPE"
              visible={true}
              width={200}
              caption={"Type"}
            >
              <Lookup
                dataSource={StatementReportTypes}
                valueExpr="TYPE"
                displayExpr="DESCRIPTION"
              />
            </Column>
            {/* <Column
              dataField={"FPTRANSACTIONCODE"}
              caption={"Type"}
              hidingPriority={7}
              allowEditing={true}
            >
              <RequiredRule message="A Transaction Code is required" />
              <Lookup
                dataSource={transTypes}
                valueExpr="FPTRANSACTIONCODE"
                displayExpr="LONGDESCRIPTION"
              />
            </Column> */}
            <Column dataField="ITEM" caption="Item" width={100} />
            <Column
              dataField="LINETYPE"
              caption="Line Type"
              visible={true}
              width={200}
            />
            <Column dataField="DESCRIPTION" caption="Description" width={300} />
            <Column
              dataField="AMOUNTONE"
              caption="Owner 1"
              format={"$###,###,###"}
              alignment="right"
              width={150}
            />
            <Column
              dataField="AMOUNTTWO"
              caption="Owner 2"
              format={"$###,###,###"}
              alignment="right"
              width={150}
            />
            <Column
              dataField="TOTALAMOUNT"
              caption="Total"
              format={"$###,###,###"}
              alignment="right"
              width={150}
            />
            <Column
              dataField="DONOTPRINT"
              dataType="boolean"
              caption="Do not Print "
              visible={true}
            />
            <Column dataField="FORMULA" caption="Formula" visible={true} />
          </DataGrid>
        </div>
      )}
    </>
  );
};

export default CFEnterStructure;
