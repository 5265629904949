import React, { useEffect, useState } from "react";
import "./import.scss";
//import SelectBox from "devextreme-react/select-box";
import {
  getBanks,
  updateExcelTransactions,
  createAssetsandInvestments,
  ImportExcelData,
} from "./importIncTaxData";
// import "./FileUploader.css"; // Importing the CSS file

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";
import { set } from "date-fns";
import { Button } from "devextreme-react/button";

import DataGrid, {
  Column,
  Paging,
  Pager,
  Sorting,
  Editing,
  FilterRow,
  Item,
  Lookup,
  Popup,
  Form,
  MasterDetail,
  HeaderFilter,
  Search,
  SearchPanel,
} from "devextreme-react/data-grid";
import { column } from "mathjs";
import { Col } from "devextreme-react/responsive-box";

//import { CheckBox } from "devextreme-react/check-box";

const ExcelJS = require("exceljs");

const ImportIncTax = (props) => {
  const [bankAccounts, setBankAccounts] = useState([]);
  const [currentBankAccountName, setCurrentBankAccountName] = useState("");
  const [currentBankAccount, setCurrentBankAccount] = useState("");
  //const [accountData, setAccountData] = useState("");
  const [ClientCode, setClientCode] = useState(props.clientCode);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showInfo, setShowInfo] = useState(false);
  const [headers, setHeaders] = useState([]); // State for storing headers
  const [rows, setRows] = useState([]); // State for storing row data
  const MySwal = withReactContent(Swal);

  const [editMode, setEditMode] = useState(false); // To toggle edit mode
  const navigate = useNavigate(); // Inside your component
  const [ShowPage, setShowPage] = useState(true); // To toggle edit mode

  const [assets, setAssets] = useState([]);
  const [investments, setInvestments] = useState([]);

  const [assetHeaders, setAssetHeaders] = useState([]);
  const [investmentHeaders, setInvestmentHeaderes] = useState([]);

  const [thisClientCode, setThisClientCode] = useState(""); // To store the client code

  const [headerDates, setHeaderDates] = useState([]); // To store the header dates

  const [importInvestments, setImportInvestments] = useState(true); // To store the header dates
  const [importAssets, setImportAssets] = useState(true); // To store the header dates

  const checkedLabel = { "aria-label": "Checked" };

  const [excelData, setExcelData] = useState([]); // To store the Excel data

  // const [columnMappings, setColumnMappings] = useState({
  //   date: "",
  //   description: "",
  //   debit: "",
  //   credit: "",
  // }); // To store user-specified column mappings

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    readExcelFile(file);
    setShowInfo(true);
  };
  //const readExcelFile = (file) => {

  const readExcelFile = async (file) => {
    try {
      const workbook = new ExcelJS.Workbook();
      const arrayBuffer = await file.arrayBuffer();
      await workbook.xlsx.load(arrayBuffer);

      const worksheet = workbook.getWorksheet("Data");

      if (!worksheet) {
        throw new Error("Worksheet 'Data' not found in the Excel file.");
      }

      const data = [];
      worksheet.eachRow((row, rowNumber) => {
        if (rowNumber <= 1) return; // Skip headers

        data.push({
          UniqueRow: rowNumber, // Use row number from Excel
          UniqueCount: rowNumber - 1, // Create a unique, sequential number
          columnA: row.values[1] || "",
          columnB: row.values[2] || "",
          columnC: row.values[3] || "",
          columnD: row.values[4] || "",
          columnE: row.values[5] || "",
          columnF: row.values[6] || "",
          columnG: row.values[7] || "",
          columnH: row.values[8] || "",
          columnI: row.values[9] || "",
          columnJ: row.values[10] || "",
          columnK: row.values[11] || "",
          columnL: row.values[12] || "",
          columnM: row.values[13] || "",
          columnN: row.values[14] || "",
          columnO: row.values[15] || "",
          columnP: row.values[16] || "",
        });
      });

      setExcelData(data); // Save processed data
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Error",
        text: error.message || "Failed to read the Excel file.",
      });
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      readExcelFile(file);
    }
  };

  const removeOuterObject = (data) => {
    // Check if 'excelData' key exists and is an array
    if (data && Array.isArray(data.excelData)) {
      return data.excelData; // Return the inner array
    }
    throw new Error(
      "Invalid structure: 'excelData' is missing or not an array"
    );
  };

  const handleFileImport = async () => {
    if (!excelData || excelData.length === 0) {
      MySwal.fire({
        icon: "error",
        title: "Error",
        text: "No data available to import. Please upload a valid file.",
      });
      return;
    }

    //Build the payload with correct structure
    let counter = 0;
    const payload = {
      excelData: excelData.map((row) => ({
        counter: counter++,
        UniqueCount: counter || "", // Handle null or undefined UniqueCount
        columnA: row.columnA != null ? row.columnA : "", // Handle both null and undefined
        columnB: row.columnB != null ? row.columnB : "",
        columnC: row.columnC != null ? row.columnC : "",
        columnD: row.columnD != null ? row.columnD : "",
        columnE: row.columnE != null ? row.columnE : "",
        columnF: row.columnF != null ? row.columnF : "",
        columnG: row.columnG != null ? row.columnG : "",
        columnH: row.columnH != null ? row.columnH : "",
        columnI: row.columnI != null ? row.columnI : "",
        columnJ: row.columnJ != null ? row.columnJ : "",
        columnK: row.columnK != null ? row.columnK : "",
        columnL: row.columnL != null ? row.columnL : "",
        columnM: row.columnM != null ? row.columnM : "",
        columnN: row.columnN != null ? row.columnN : "",
        columnO: row.columnO != null ? row.columnO : "",
        columnP: row.columnP != null ? row.columnP : "",
      })),
    };

    // Flatten the data if required
    const flattenedData = excelData.map((row) => ({
      Counter: row.UniqueCount || "",
      ReportGroup: row.columnA != null ? row.columnA : "",
      StatementType: row.columnB != null ? row.columnB : "",
      Description: row.columnC != null ? row.columnC : "",
      ReportingSequence: row.columnD != null ? row.columnD : "",
      ColumnForOwner1: row.columnE != null ? row.columnE : "",
      ColumnForOwner2: row.columnF != null ? row.columnF : "",
      PrintDetailRows: row.columnG != null ? row.columnG : "",
      Heading: row.columnH != null ? row.columnH : "",
      Footing: row.columnI != null ? row.columnI : "",
      PrintHeading: row.columnJ != null ? row.columnJ : "",
      PrintFooting: row.columnK != null ? row.columnK : "",
      TotalLevel: row.columnL != null ? row.columnL : "",
      PrintOwnerOnHeading: row.columnM != null ? row.columnM : "",
      DoNotPrintSection: row.columnN != null ? row.columnN : "",
      TotalOnly: row.columnO != null ? row.columnO : "",
      Formula: row.columnP != null ? row.columnP : "",
    }));

    console.log("Structured Payload:", payload);
    console.log("Flattened Data:", flattenedData);

    try {
      const response = await ImportExcelData(flattenedData); // Send the payload to API
      MySwal.fire({
        icon: "success",
        title: "Data Imported",
        text: `Data has been imported successfully.`,
      });
      console.log("API Response:", response);
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Import Failed",
        text: "An error occurred while importing data. Please try again.",
      });
      console.error("Import error:", error);
    }
  };

  function handleimportInvestmentsChange(e) {
    setImportInvestments(e.value);
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "20px",
          justifyContent: "left",
          alignItems: "center",
        }}
      >
        <p></p>

        <div>
          <h2>Excel Import and Grid Display</h2>
          <input
            type="file"
            onChange={handleFileChange}
            accept=".xlsx, .xls"
            style={{
              width: "200px",
              height: "30px",
              marginTop: "2px",
              marginBottom: "10px",
              marginLeft: "15px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              padding: "4px",
              fontSize: "14px",
              cursor: "pointer",
            }}
          />
          <div style={{ marginTop: "20px" }}></div>
          <Button
            text="Import Data"
            onClick={handleFileImport}
            style={{
              width: "200px",
              height: "30px",
              marginTop: "2px",
              marginBottom: "10px",
              marginLeft: "15px",
            }}
          />
        </div>
        <div>
          <h2>Combined Excel Data</h2>
          {excelData.length > 0 ? (
            <DataGrid
              dataSource={excelData}
              showBorders={true}
              columnAutoWidth={true}
              keyExpr="UniqueCount" // Ensure UniqueCount is unique for each row
            >
              <Column dataField="UniqueCount" caption="Line Number" />
              <Column dataField="columnA" caption="Column A" />
              <Column dataField="columnB" caption="Column B" />
              <Column dataField="columnC" caption="Column C" />
              <Column dataField="columnD" caption="Column D" />
              <Column dataField="columnE" caption="Column E" />
              <Column dataField="columnF" caption="Column F" />
              <Column dataField="columnG" caption="Column G" />
              <Column dataField="columnH" caption="Column H" />
              <Column dataField="columnI" caption="Column I" />
              <Column dataField="columnJ" caption="Column J" />
              <Column dataField="columnK" caption="Column K" />
              <Column dataField="columnL" caption="Column L" />
              <Column dataField="columnM" caption="Column M" />
              <Column dataField="columnN" caption="Column N" />
              <Column dataField="columnO" caption="Column O" />
              <Column dataField="columnP" caption="Column P" />
            </DataGrid>
          ) : (
            <p>No data available</p>
          )}
        </div>
      </div>
    </>
  );
};

export default ImportIncTax;
