import React, { useState, useEffect, useCallback, useRef } from "react";

import CustomStore from "devextreme/data/custom_store";

//import { Popup, Position, ToolbarItem } from "devextreme-react/popup";
import DataGrid, {
  Column,
  Editing,
  Sorting,
  Lookup,
  //MasterDetail,
  //Popup,
  Form,
  HeaderFilter,
  FilterRow,
  //SearchPanel,
  Paging,
  Item,
  AsyncRule,
  //ValidationRule,
  SearchPanel,
  Selection,
} from "devextreme-react/data-grid";

import SelectBox from "devextreme-react/select-box";
import DateBox from "devextreme-react/date-box";
import { Validator, RequiredRule } from "devextreme-react/validator";

import "devextreme-react/text-area";
import "./app.scss";
import "./clientTransactions.css";

import { useAuth } from "../../../contexts/auth";

import "devextreme/data/data_source";

//import DataSource from "devextreme/data/data_source";
import {
  getTransactionTypes,
  getBanks,
  fetchThisClientData,
  getInvestmentRecords,
} from "./segmentData";
//import { mystore5 } from "./segmentData2";
import { Button } from "devextreme-react";

import Swal from "sweetalert2";
//import { myStore5 } from "./clientBanksAccountsData";

let pageoption = 90;

function ClientTransactionsx(props) {
  const dataGridRef = useRef(null); // Reference for DataGrid
  //const [dataSourcex, setDataSource] = useState(null);
  const [transTypes, setTransTypes] = useState(null);
  const [bankAccounts, setBankAccounts] = useState(null);
  const [lastBankAccountNumber, setLastBankAccountNumber] = useState("");
  const [lastSegmentNumbmer, setLastSegmentNumber] = useState("");
  const [clientCode, setClientCode] = useState(props.clientCode);
  const [currentBankAccount, setCurrentBankAccount] = useState("");

  const [filterValue, setFilterValue] = useState(0); // for dates - the actual number of days
  const [refreshKey, setRefreshKey] = useState(0); // for dates to refresh grid when date changed
  const [filterOption, setFilterOption] = useState(0); // for dates - passed to mydate5
  const [startdate, setStartdate] = useState(null);
  const [enddate, setEnddate] = useState(null);
  const [OpeningBalance, setOpeningBalance] = useState(0);
  const [ClosingBalance, setClosingBalance] = useState(0);
  const [usePopup, setUsePopup] = useState(false);
  const [investmentlist, setInvestmentList] = useState(null);

  const handleStartDateChange = (e) => {
    setStartdate(e.value);
    setRefreshKey((prevKey) => prevKey + 1);
  };

  const handleEndDateChange = (e) => {
    setEnddate(e.value);
    setRefreshKey((prevKey) => prevKey + 1);
  };

  const [showFilterRow, setShowFilterRow] = useState(true);

  const applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];

  const [currentFilter, setCurrentFilter] = useState(applyFilterTypes[0]);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);

  const dateFilterOptions = [
    { value: 30, text: "Last 30 days" },
    { value: 60, text: "Last 60 days" },
    { value: 90, text: "Last 90 days" },
    { value: 999999, text: "All Transactions" },
    { value: 0, text: "Processing Dates" },
  ];

  const handleFilterChange = (e) => {
    // Update the filterValue state
    setFilterValue(e.value);
  };

  const onEditorPreparing = (e) => {
    // Check if the row is not new
    if (e.parentType === "dataRow" && !e.row.isNewRow) {
      //Disable editing for a specific field
      if (e.dataField === "SEGMENTNUMBER") {
        e.editorOptions.disabled = true;
      }
      if (e.dataField === "BANKACCOUNTNUMBER") {
        e.editorOptions.disabled = true;
      }
      if (e.dataField === "DESCRIPTION") {
        e.editorOptions.disabled = true;
      }
    }
  };

  const onRowInserted = useCallback(
    (e) => {
      setLastBankAccountNumber(e.data.BANKACCOUNTNUMBER);
      //console.log(e);
      setLastSegmentNumber(e.data.SEGMENTNUMBER);
      //console.log("last bank account number", lastBankAccountNumber);
    },
    [lastBankAccountNumber]
  );

  // old version const onRowInserted = (e) => {
  //   setLastBankAccountNumber(e.data.BANKACCOUNTNUMBER);
  //   console.log(e);
  //   console.log("last bank account number", lastBankAccountNumber);
  // };

  const validateSegment = async (params) => {
    if (!params.data.SEGMENTNUMBER) {
      return true;
    }
    // console.log(
    //   "params coming in ",
    //   params.data.SEGMENTNUMBER,
    //   params.data.BANKACCOUNTNUMBER,
    //   clientCode
    // ); // , "bankid: ", bankUniqueid);
    return await asyncValidation(
      params.data.BANKACCOUNTID,
      clientCode,
      params.data.SEGMENTNUMBER
    );
  };

  const onInitNewRow = (e) => {
    console.log("last bank account number", lastBankAccountNumber);
    e.data.BANKACCOUNTNUMBER = lastBankAccountNumber;
    e.data.SEGMENTNUMBER = lastSegmentNumbmer;
  };

  useEffect(() => {
    fetchThisClientData(props.clientCode) // call the function to fetch data
      .then((data) => {
        console.log("bank numbers", data);
        setStartdate(data.STARTDATE); // store the data in state
        setEnddate(data.ENDDATE); // store the data in state
        setBankAccounts(data.data); // store the data in state
        //console.log("new types", transTypes);
      })
      .catch((error) => {
        console.error(
          "There was an error fetching the transaction Types data:",
          error
        );
      });
    getTransactionTypes() // call the function to fetch data
      .then((data) => {
        //console.log("types", data.data);
        setTransTypes(data.data); // store the data in state
        //console.log("new types", transTypes);
      })
      .catch((error) => {
        console.error(
          "There was an error fetching the transaction Types data:",
          error
        );
      });
    getBanks(props.clientCode) // call the function to fetch data
      .then((data) => {
        console.log("bank numbers", data);
        setBankAccounts(data.data); // store the data in state
        //console.log("new types", transTypes);
      })
      .catch((error) => {
        console.error(
          "There was an error fetching the transaction Types data:",
          error
        );
      });
  }, []);

  useEffect(() => {
    getBanks(props.clientCode) // call the function to fetch data
      .then((data) => {
        console.log("bank numbers", data);
        setBankAccounts(data.data); // store the data in state
        //console.log("new types", transTypes);
      })
      .catch((error) => {
        console.error(
          "There was an error fetching the transaction Types data:",
          error
        );
      });
    fetchThisClientData(props.clientCode) // call the function to fetch data
      .then((data) => {
        console.log("bank numbers", data);
        setStartdate(data.STARTDATE); // store the data in state
        setEnddate(data.ENDDATE); // store the data in state
        setBankAccounts(data.data); // store the data in state
        //console.log("new types", transTypes);
      })
      .catch((error) => {
        console.error(
          "There was an error fetching the transaction Types data:",
          error
        );
      });

    getInvestmentRecords(props.clientCode) // call the function to fetch data
      .then((data) => {
        setInvestmentList(data.data); // store the data in state
      })
      .catch((error) => {
        console.error(
          "There was an error fetching the transaction Types data:",
          error
        );
      });
  }, [props]);

  // Use useEffect to perform actions when filterValue changes
  useEffect(() => {
    console.log("New filter value:", filterValue);
    setFilterOption(filterValue);
    setRefreshKey((prevKey) => prevKey + 1);
    console.log(refreshKey);
  }, [filterValue]); // Add filterValue as a dependency

  // Rest of your component

  const setAccountData = (e) => {
    setCurrentBankAccount(e.value);
  };
  const clearBankAccount = (e) => {
    setCurrentBankAccount("");
  };

  const handleGridContentReady = (e) => {
    // Log the parameters to ensure they have the correct values
    console.log(
      "stuff :",
      props.clientCode,
      "filter option: ",
      filterOption,
      "start date: ",
      startdate,
      "end date: ",
      enddate,
      "bank account: ",
      currentBankAccount
    );

    // Package the parameters as an object to match what Getbalances expects
    const params = {
      bankID: props.clientCode, // Assuming clientCode is the bank ID
      rangeValue: filterOption,
      startdate: startdate,
      enddate: enddate,
      currentBankAccount: currentBankAccount,
    };

    // Now pass the parameters as an object
    Getbalances(params)
      .then((balances) => {
        // Update your UI or state with the new balances
        setOpeningBalance(balances.OpeningBalance);
        setClosingBalance(balances.ClosingBalance);
      })
      .catch((error) => {
        console.error("Failed to fetch balances", error);
      });
  };

  const handleDeleteSelectedRows = async () => {
    if (!dataGridRef.current || !dataGridRef.current.instance) {
      Swal.fire(
        "Error",
        "Grid is not ready yet. Please try again later.",
        "error"
      );
      return;
    }

    const selectedRowKeys = dataGridRef.current.instance.getSelectedRowKeys();
    if (selectedRowKeys.length > 0) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete them!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            // Call the remove method for each selected row key
            const deletePromises = selectedRowKeys.map((key) => {
              return mystore5(
                clientCode,
                filterOption,
                startdate,
                enddate,
                currentBankAccount
              ).remove(key);
            });

            // Wait for all delete operations to complete
            await Promise.all(deletePromises);

            // Refresh the data source
            dataGridRef.current.instance.refresh();
            setRefreshKey((prevKey) => prevKey + 1);
            Swal.fire(
              "Deleted!",
              "The selected rows have been deleted.",
              "success"
            );
          } catch (error) {
            console.error("Error deleting rows:", error);
            Swal.fire(
              "Error",
              "Failed to delete records. Please try again.",
              "error"
            );
          }
        }
      });
    } else {
      Swal.fire("No rows selected", "Please select rows to delete.", "info");
    }
  };

  // const updateBalances = () => {
  //   // Your logic to update OpeningBalance and ClosingBalance
  //   // This could involve API calls or calculations based on current grid data
  //   const gridData = daSource.items(); // Or however you access the grid data
  //   let openingBalance = 0;
  //   let closingBalance = 0;

  //   // Example logic to calculate balances
  //   if (gridData.length > 0) {
  //       openingBalance = gridData[0].OpeningBalance;
  //       closingBalance = gridData[gridData.length - 1].ClosingBalance;
  //   }

  //   // Update your state or UI with the new balances
  //   setOpeningBalance(openingBalance);
  //   setClosingBalance(closingBalance);
  // };

  const handleCheckboxChange = (e) => {
    setUsePopup(e.target.checked);
    setRefreshKey((prevKey) => prevKey + 1); // Trigger refresh if needed
  };

  return (
    <>
      <div className="red-color responsive-paddingsx">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>Bank Transactions</span>
          <div>
            <label>Bank Account:</label>
            <SelectBox
              style={{ width: "250px", height: "30px" }}
              items={bankAccounts}
              valueExpr="BANKACCOUNTNUMBER"
              displayExpr={(item) =>
                item
                  ? `${item.BANKNAME} - ${item.BANKACCOUNTNUMBER} - ${item.ACCOUNTDESCRIPTION}`
                  : ""
              }
              //displayExpr="BANKACCOUNTNUMBER"
              //displayExpr={(item) =>
              //  `${item.BANKACCOUNTNUMBER} - ${item.DESCRIPTION}`
              // }
              value={currentBankAccount}
              searchEnabled={true}
              //value={currentEmployeeName}
              onValueChanged={setAccountData}
              //onValueChanged={(e) => setCurrentEmployeeName(e.value)}
            />
          </div>

          <div>
            <label>
              Start Date (MM/DD/YYYY):
              <DateBox
                type="date"
                value={startdate}
                onValueChanged={handleStartDateChange}
              />
            </label>
          </div>
          <div>
            <label>
              End Date (MM/DD/YYYY):
              <DateBox
                type="date"
                value={enddate}
                onValueChanged={handleEndDateChange}
              />
            </label>
          </div>

          <SelectBox
            dataSource={dateFilterOptions}
            valueExpr="value"
            displayExpr="text"
            placeholder="Select date range"
            value={filterValue}
            onValueChanged={handleFilterChange}
            dropDownOptions={{
              width: 250, // Set the width of the dropdown menu
              height: 200, // Set the height of the dropdown menu
            }}
            width={250} // Set the width of the select box
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "50%",
          }}
        >
          <Button text="Clear Account Selection" onClick={clearBankAccount} />

          <Button
            style={{ margin: "10px 1px" }}
            text="Delete Selected Rows"
            onClick={handleDeleteSelectedRows}
          />

          {currentBankAccount !== "" && (
            <>
              <p style={{ margin: "0 20px" }}>
                Opening Balance: {OpeningBalance}{" "}
              </p>
              <p style={{ margin: "0 20px" }}>
                Closing Balance: {ClosingBalance}{" "}
              </p>
            </>
          )}
          {/* <div style={{ marginBottom: "10px", marginTop: "10px" }}>
            <label>
              <input
                type="checkbox"
                checked={usePopup}
                onChange={handleCheckboxChange}
              />
              Detailed Edit
            </label>
          </div> */}
        </div>
        {/* className="content-block dx-card responsive-paddings red-color">*/}

        <p> </p>
        <div className="custom-container">
          <DataGrid
            ref={dataGridRef}
            dataSource={mystore5(
              props.clientCode,
              filterOption,
              startdate,
              enddate,
              currentBankAccount
            )}
            onContentReady={handleGridContentReady}
            key={refreshKey} // This key will force a refresh when it changes
            columnAutoWidth={true}
            onEditorPreparing={onEditorPreparing}
            onInitNewRow={onInitNewRow}
            onRowInserted={onRowInserted}
            width={"100%"}
            //columnHidingEnabled={true}
            // paging={{ pageSize: 10 }}
            // pagingEnabled={true}
            remoteOperations={false}
          >
            <Selection mode="multiple" />
            <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
            <HeaderFilter visible={showHeaderFilter} />
            <SearchPanel visible={false} width={240} placeholder="Search..." />
            {usePopup ? (
              // Popup Editing
              <Editing
                mode="popup"
                allowUpdating={true}
                allowAdding={true}
                allowDeleting={true}
                popup={{
                  title: "Edit Record",
                  showTitle: true,
                  width: 700,
                  height: 525,
                }}
                form={{
                  colCount: 2,
                }}
              />
            ) : (
              // Inline Cell Editing
              <Editing
                mode="cell"
                allowUpdating={true}
                allowAdding={true}
                allowDeleting={true}
              />
            )}
            <Form>
              <Item itemType="group" colCount={3} colSpan={3}>
                <Item dataField="BANKACCOUNTNUMBER" />
                <Item dataField="SEGMENTNUMBER" />
                <Item dataField="TRANSACTIONDATE" />
                <Item dataField="DESCRIPTION" />
                <Item dataField="SECONDDESCRIPTION" />
                <Item dataField="FPTRANSACTIONCODE" />
                <Item dataField="TRANSACTIONAMOUNT" />
                <Item dataField="INTERESTCOST" />
                <Item dataField="INTERESTPOSTED" />
                <Item dataField="INTERESTCHARGE" />

                <Item dataField="ADJUSTMENT" />
              </Item>
            </Form>

            <Column
              allowFiltering={true}
              dataField={"BANKACCOUNTID"}
              caption={"Bank Account"}
              hidingPriority={7}
              allowEditing={true}
            >
              <Lookup
                dataSource={bankAccounts}
                valueExpr="UNIQUEID"
                //displayExpr="BANKACCOUNTNUMBER"
                displayExpr={(item) =>
                  item
                    ? `${item.BANKNAME} - ${item.BANKACCOUNTNUMBER} - ${item.ACCOUNTDESCRIPTION}`
                    : ""
                }
              />
            </Column>
            <Column
              dataField={"SEGMENTNUMBER"}
              caption={"Seg"}
              hidingPriority={7}
              allowEditing={true}
              width={100}
            >
              <AsyncRule
                message="Segment Does Not Exists"
                validationCallback={validateSegment}
              />
            </Column>
            <Column
              dataType="date"
              dataField={"TRANSACTIONDATE"}
              caption={"Date"}
              hidingPriority={7}
              allowEditing={true}
            >
              <RequiredRule message="A Date is required" />
            </Column>
            <Column
              dataField={"DESCRIPTION"}
              caption="Bank Description"
              allowEditing={true}
            />
            <Column
              dataField={"SECONDDESCRIPTION"}
              caption="Details"
              allowEditing={true}
              width={200}
            />
            <Column
              dataField={"FPTRANSACTIONCODE"}
              caption={"Type"}
              hidingPriority={7}
              allowEditing={true}
            >
              <RequiredRule message="A Transaction Code is required" />
              <Lookup
                dataSource={transTypes}
                valueExpr="FPTRANSACTIONCODE"
                displayExpr="LONGDESCRIPTION"
              />
            </Column>

            <Column
              dataField={"TRANSACTIONAMOUNT"}
              caption={"Amount"}
              hidingPriority={7}
              allowEditing={true}
              format="$###,###,###.00"
            />
            <Column
              dataType={"boolean"}
              dataField={"INTERESTCHARGE"}
              caption={"Int Chrge"}
              hidingPriority={7}
              allowEditing={true}
              visible={true}
            />
            <Column
              dataType={"boolean"}
              dataField={"INTERESTCOST"}
              caption={"Int Cost"}
              hidingPriority={7}
              allowEditing={true}
              visible={true}
              width={75}
            />
            <Column
              dataType={"boolean"}
              dataField={"INTERESTPOSTED"}
              caption={"Int Pstd"}
              hidingPriority={7}
              allowEditing={false}
              visible={true}
              width={75}
            />
            <Column
              dataType={"boolean"}
              dataField={"ADJUSTMENT"}
              caption={"Adj"}
              hidingPriority={7}
              allowEditing={true}
              visible={true}
            />
            <Column
              dataField={"INVESTMENTID"}
              caption="Invest"
              allowEditing={true}
              width={200}

              //editCellComponent={PrepareInvestmentID}
            >
              <Lookup
                dataSource={investmentlist}
                valueExpr="UNIQUEID"
                //displayExpr="INVESTMENTNAME"
                displayExpr={(item) =>
                  item
                    ? `${item.INVESTMENTNAME} - ${item.INVESTMENTSUBGROUP}  `
                    : ""
                }
                //allowClearing={true}
              />
            </Column>
            {/* <Column
              dataField={"UNIQUEID"}
              caption={"uniqueid"}
              hidingPriority={7}
              allowEditing={false}
              visible={false}
            /> */}
          </DataGrid>
        </div>
      </div>
    </>
  );
}

async function asyncValidation(bankAccountNumber, clientCode, segmentNumber) {
  var requestoptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json;",
    },
    body: JSON.stringify({
      sentBankAccountNumber: bankAccountNumber,
      sentSegment: segmentNumber,
      sentClientCode: clientCode,
    }),
  };
  //console.log("bankID", bankID, "segment", segmentNumber);
  const url = `${process.env.REACT_APP_BASE_URL}/fetchThisClientSegment2`;

  const response = await fetch(url, requestoptions);
  if (!response.ok) {
    throw new Error("System did not respond");
  }
  const data = await response.json();
  console.log("data from fetch", data.user_response.response);
  if (data.user_response.response === "ERROR") {
    return true; // Validation successful
  } else if (data.user_response.response === "OK") {
    return false; // Validation failed
  } else {
    throw new Error("Unexpected response");
  }
}

//export default ClientTransactions;

export default function ClientTransactions() {
  const { user } = useAuth();
  //console.log("my user stuff", { user });
  return <ClientTransactionsx clientCode={user.thisClientcode} />;
}

// async function getTasks(key, masterField) {
//   //console.log("call to datasource2", key, "range is", masterField);
//   // const store = mystore3(key, masterField);
//   // const loadResult = await store.load();
//   return new DataSource(mystore3(key));
// }

/////////////////////////////
// async function getTasks(key, masterField) {
//   console.log("call to datasource2", key, "range is", masterField);

//   const store = mystore3(key, masterField);
//   const loadResult = await store.load();
//   return new DataSource({ store, load: () => loadResult });
// }

// <Column
//   dataField={"TOTALSERVICECOST"}
//   caption={"Total Service Cost"}
//   hidingPriority={7}
//   allowEditing={false}
//   format="##.00"
//   calculateCellValue={this.calculateSalary}
// />

//<Item dataField="TOTALSERVICECOST" />
//className="content-block dx-card responsive-paddings">

//const { DESCRIPTION } = this.props.data;
//const dateoptions = 60;
//const { rowData, masterField } = this.props;
//console.log("props ", this.props);
//this.dataSource = getTasks(props.rowid, props.sendit);
//console.log(this.dataSource);

//const { selectedItem, onItemClicked } = this.props;

// handleDataChanged(e) {
//   e.component.repaintRows();
// }

// async componentDidMount() {
//   const dataSource = await getTasks(this.props.rowid, this.props.sendit);
//   this.setState({ dataSource });
// }

const Getbalances = async ({
  bankID,
  rangeValue,
  startdate,
  enddate,
  currentBankAccount,
}) => {
  var requestoptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json;",
    },

    body: JSON.stringify({
      sentbankid: bankID,
      daterange: rangeValue,
      startdate: startdate,
      enddate: enddate,
      currentBankAccount: currentBankAccount,
    }),
  };

  console.log(
    "input bankid",
    bankID,
    "rangevalue: ",
    rangeValue,
    "start date: ",
    startdate,
    "enddate:",
    enddate,
    "bank account:",
    currentBankAccount
  );
  const url = `${process.env.REACT_APP_BASE_URL}/getBalances`;
  return fetch(url, requestoptions) // Request fish
    .then((response) => {
      if (!response.ok) {
        return {
          companyname: "System did not respond",
          returnaddress: " ",
        };
      }
      return response.json();
    })
    .then((json) => {
      console.log("data for getbalances stuff", json.user_response);
      return {
        OpeningBalance: json.user_response.OpeningBalance,
        ClosingBalance: json.user_response.ClosingBalance,
      };
    });
};

function isNotEmpty(value) {
  return value !== undefined && value !== null && value !== "";
}
export const mystore5 = (
  bankID,
  rangeValue,
  startdate,
  enddate,
  currentBankAccount
) =>
  new CustomStore({
    key: "UNIQUEID",
    load: (loadOptions) => {
      let params = "?";
      [
        "skip",
        "take",
        "requireTotalCount",
        "requireGroupCount",
        "sort",
        "filter",
        "totalSummary",
        "group",
        "groupSummary",
      ].forEach((i) => {
        if (i in loadOptions && isNotEmpty(loadOptions[i])) {
          params += `${i}=${JSON.stringify(loadOptions[i])}&`;
        }
      });
      //myemployee = "b@b.com";
      //mycompany = 1;
      //myemployee = "b@b.com";
      ////console.log("bank", bankID, "range", rangeValue);
      ////console.log(rangeValue);
      params = params.slice(0, -1);
      var requestoptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json;",
        },
        body: JSON.stringify({
          sentbankid: bankID,
          Parameters: params,
          daterange: rangeValue,
          startdate: startdate,
          enddate: enddate,
          currentBankAccount: currentBankAccount,
        }),
      };
      // console.log(
      //   "startdate definite",
      //   startdate,
      //   "end date definite",
      //   enddate
      // );
      const url = `${process.env.REACT_APP_BASE_URL}/returnClientbanktransactionsOnly`;

      return fetch(url, requestoptions) // Request fish
        .then((response) => {
          ////console.log("client " + myClient);
          if (!response.ok) {
            return {
              companyname: "System did not respond",
              returnaddress: " ",
            };
          }
          return response.json();
        })
        .then((json) => {
          console.log("json from mystore5", json);
          return {
            data: json.user_response.bankq,
            totalCount: json.user_response.totalCount,
            key: json.user_response.keyname,
            //OpeningBalance: json.user_response.OpeningBalance,
            //ClosingBalance: json.user_response.ClosingBalance,
          };
        });
    },
    insert: (values) => {
      ////console.log(values, bankID);
      var requestoptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json;",
        },
        body: JSON.stringify({
          ThisFunction: "insert",
          keyvaluepair: values,
          sentcompany: bankID,
        }),
      };
      //console.log("values", values, "ID:", bankID);
      const url = `${process.env.REACT_APP_BASE_URL}/UpdateClientTransactions`;
      return fetch(url, requestoptions) // Request fish
        .then((response) => {
          if (!response.ok) {
            return {
              companyname: "System did not respond",
              returnaddress: " ",
            };
          }
          return response.json();
        })
        .then((json) => {
          return json.user_response.keyvaluepair;
        });
    },
    remove: (key) => {
      ////console.log(key);
      ////console.log(values);
      var requestoptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json;",
        },
        body: JSON.stringify({
          sentcompany: key,
          ThisFunction: "delete",
        }),
      };
      //console.log("Key", key);
      const url = `${process.env.REACT_APP_BASE_URL}/UpdateClientTransactions`;
      return fetch(url, requestoptions) // Request fish
        .then((response) => {
          if (!response.ok) {
            return {
              companyname: "System did not respond",
              returnaddress: " ",
            };
          }
          return response.json();
        })
        .then((json) => {
          return {};
        });
    },
    update: (key, values) => {
      ////console.log(key);
      ////console.log(values);
      var requestoptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json;",
        },
        body: JSON.stringify({
          ThisFunction: "change",
          sentcompany: key,
          keyvaluepair: values,
        }),
      };

      const url = `${process.env.REACT_APP_BASE_URL}/UpdateClientTransactions`;
      return fetch(url, requestoptions) // Request fish
        .then((response) => {
          if (!response.ok) {
            return {
              companyname: "System did not respond",
              returnaddress: " ",
            };
          }
          return response.json();
        })
        .then((json) => {
          return {};
        });
    },
  });
