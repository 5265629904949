import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import FormulaParser from "fast-formula-parser";
import SelectBox from "devextreme-react/select-box";
import Spreadsheet from "react-spreadsheet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import {
  importstandard,
  GetInvestmentGroups,
  PullExcelcashFlow,
} from "./excelFormData";
import "./spreadsheetStyles.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { th } from "date-fns/locale";
import { use } from "react";
import { set } from "date-fns";

const ExcelForm = (props) => {
  const [customercode] = useState(props.clientCode);
  const [InvestmentGroups, setInvestmentGroups] = useState([]);
  const [currentGroup, setCurrentGroup] = useState("");
  const [hideColumns, setHideColumns] = useState(false);
  const [data, setData] = useState([]);
  const MySwal = withReactContent(Swal);
  const [isLoading, setIsLoading] = useState(false);
  const [newExcelFile, setNewExcelFile] = useState("");

  const initialData = [
    [
      { value: "Code" },
      { value: "Description" },
      { value: "Him" },
      { value: "Her" },
      { value: "Total" },
    ],
  ];

  useEffect(() => {
    GetInvestmentGroups()
      .then((response) => {
        setInvestmentGroups(response.data);
      })
      .catch((error) => {
        console.error("Error loading investment groups:", error);
      });
  }, []);

  useEffect(() => {
    const updatedData = calculateData(initialData);
    setData(updatedData);
  }, [hideColumns]);

  const LoadDataFromStandard = () => {
    importstandard(customercode, currentGroup)
      .then((response) => {
        const transformedData = transformData(response.data);
        setData(transformedData);
      })
      .catch((error) => {
        console.error("Error loading data from standard:", error);
      });
  };

  const transformData = (data) => {
    return data.map((item) => {
      const isHeader = item.LINETYPE === "H";
      const isFooter = item.LINETYPE === "T";
      const commonClass = isHeader
        ? "blackongold"
        : isFooter
        ? "blackongrey"
        : "";

      return [
        {
          value: item.SEQUENCE || "",
          className: hideColumns ? "hidden-column" : "",
        },
        {
          value: item.DESCRIPTION || "",
          className: isHeader ? "whiteonblack" : commonClass,
        },
        { value: item.HISVALUE ?? "", className: commonClass },
        { value: item.HERVALUE ?? "", className: commonClass },
        { value: item.TOTALVALUE ?? "", className: commonClass },
        { value: item.LINETYPE || "", className: commonClass },
      ];
    });
  };

  const calculateData = (currentData) => {
    return currentData.map((row, rowIndex) => {
      if (rowIndex === 0) return row;
      return row.map((cell) => {
        return {
          ...cell,
          className: hideColumns ? "hidden-column" : cell.className,
        };
      });
    });
  };

  const parser = new FormulaParser({
    onCell: ({ row, col }) => {
      const refCell = data[row - 1]?.[col - 1];
      if (!refCell) return 0; // Default to 0 if the cell doesn't exist

      const value =
        typeof refCell.value === "string" && refCell.value.startsWith("=")
          ? evaluateCell(refCell, row - 1, col - 1)
          : refCell.value;

      console.log("value", value);
      return !isNaN(Number(value)) ? Number(value) : 0; // Return numeric value or 0
    },
    onRange: ({ from, to }) => {
      const result = [];
      for (let row = from.row; row <= to.row; row++) {
        for (let col = from.col; col <= to.col; col++) {
          const cell = data[row - 1]?.[col - 1];
          const value =
            typeof cell?.value === "string" && cell.value.startsWith("=")
              ? evaluateCell(cell, row - 1, col - 1)
              : cell?.value;

          result.push(!isNaN(Number(value)) ? Number(value) : 0); // Default to 0
        }
      }
      return result;
    },
  });

  const evaluateCell = (cell, rowIndex, colIndex, evaluatedCache = {}) => {
    return cell.value;
  };

  const handleSave = async () => {
    setIsLoading(true);
    const evaluatedCache = {};

    //First pass: Precompute all dependent formulas
    const firstPassData = data.map((row, rowIndex) =>
      row.map((cell, colIndex) =>
        evaluateCell(cell, rowIndex, colIndex, evaluatedCache)
      )
    );

    console.log("First Pass Evaluated Data:", firstPassData);

    // Save to Excel file
    // const worksheet = XLSX.utils.aoa_to_sheet(firstPassData);
    // const workbook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    // XLSX.writeFile(workbook, "SpreadsheetData.xlsx");

    // Transform data for backend
    const transformedData = firstPassData.map((row) => ({
      rownumber: row[0],
      Description: row[1],
      AmountOne: row[2],
      AmountTwo: row[3],
      TotalAmount: row[4],
      hisvalue: row[2],
      hervalue: row[3],
      Totalvalue: row[4],
      LineType: row[5],
    }));

    console.log("Transformed Data for Backend:", transformedData);

    try {
      await UpdateCFDataIncomeExcel(
        customercode,
        transformedData,
        currentGroup
      );
      console.log("Data saved to backend successfully.");
    } catch (error) {
      console.error("Error saving data to backend:", error);
    }
    try {
      const response = await GenerateExcelcashFlow(customercode, currentGroup);
      console.log("response", response);
      setNewExcelFile(response.filename);

      console.log("Excel file created", newExcelFile);
    } catch (error) {
      console.error("Error saving data to backend:", error);
    }

    setCurrentGroup("");
    setIsLoading(false);
  };

  const handleChange = (updatedData) => {
    setData(updatedData);
  };

  const setCurrentFGGroup = (e) => {
    setCurrentGroup(e.value);
    //pull the current values from the backend
  };
  useEffect(() => {
    setIsLoading(true);
    PullExcelcashFlow(customercode, currentGroup)
      .then((response) => {
        console.log("response", response);
        const transformedData = transformData(response.data);
        setData(transformedData);
      })
      .catch((error) => {
        console.error("Error loading data from standard:", error);
      });
    setIsLoading(false);
  }, [currentGroup]);

  // const handleDownload = (filename) => {
  //   // const filename = "DynamicData_123456789.xlsx"; // Replace with the actual filename
  //   downloadFile(filename);
  //   setNewExcelFile("");
  // };

  const handleDownload = (filename) => {
    const baseUrl = process.env.REACT_APP_BASE_URL_AXIOS; // Ensure the URL points to the backend
    const downloadUrl = `${baseUrl}/download/${filename}`; // Construct the download URL

    // Redirect to the download URL to initiate the file download
    window.location.href = downloadUrl;
    setNewExcelFile("");
  };

  return (
    <div className="spreadsheet-container">
      <div>
        <div className="app2" style={{ display: "flex", alignItems: "left" }}>
          <div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(6, auto)",
                gap: "10px",
              }}
            >
              <SelectBox
                className="white-text-selectbox"
                style={{
                  width: "200px",
                  height: "40px",
                  marginRight: "10px",
                }}
                items={InvestmentGroups}
                valueExpr="REPORTGROUP"
                displayExpr="REPORTGROUP"
                value={currentGroup}
                searchEnabled={true}
                onValueChanged={setCurrentFGGroup}
              />
              {newExcelFile && (
                <button onClick={() => handleDownload(newExcelFile)}>
                  Generate and Download Excel File
                </button>
              )}

              <div>
                {currentGroup && (
                  <>
                    <button
                      className="styled-button"
                      onClick={() => setHideColumns((prev) => !prev)}
                    >
                      {hideColumns ? "Show Columns" : "Hide Columns"}
                    </button>
                    <button
                      className="styled-button"
                      onClick={() => LoadDataFromStandard(customercode)}
                    >
                      Import From Standard
                    </button>
                    <button
                      className="styled-button save-button"
                      onClick={handleSave}
                    >
                      Save Data to File
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading && (
        <div
          className="spinner-container"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "50vh", // Full height of the viewport for proper vertical centering
          }}
        >
          <p style={{ marginBottom: "100px", fontSize: "24px" }}>
            Processing, please wait &nbsp;&nbsp;
          </p>
          <FontAwesomeIcon
            icon={faSpinner}
            spin
            className="large-spinner"
            style={{ fontSize: "48px" }} // Larger spinner size
          />
        </div>
      )}

      <h2>Financial Spreadsheet Data Entry for {customercode}</h2>

      <Spreadsheet data={data} onChange={handleChange} />
    </div>
  );
};

export default ExcelForm;

export const UpdateCFDataIncomeExcel = async (myClient, elementarray, type) => {
  const requestoptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json;",
    },
    body: JSON.stringify({
      sentclientcode: myClient,
      assetarray: elementarray,
      reporttype: type,
    }),
  };
  const url = `${process.env.REACT_APP_BASE_URL}/updateCFIncomeDataExcel2`;
  const response = await fetch(url, requestoptions);
  if (!response.ok) {
    return { data: [], error: "System did not respond" };
  }
  const json = await response.json();
  return { data: json.user_response.bankq };
};

export const GenerateExcelcashFlow = async (myClient, currentGroup) => {
  const requestoptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json;",
    },
    body: JSON.stringify({
      ClientCode: myClient,
      currentGroup: currentGroup,
    }),
  };
  const url = `${process.env.REACT_APP_BASE_URL}/GenerateExcelcashFlow`;
  const response = await fetch(url, requestoptions);
  if (!response.ok) {
    return { data: [], error: "System did not respond" };
  }
  const json = await response.json();
  console.log("json", json);
  return {
    data: json.user_response.bankq,
    filename: json.user_response.newfilename,
  };
};

const downloadFile = async (filename) => {
  try {
    const response = await fetch(`http://localhost:4000/download/${filename}`, {
      method: "GET",
    });

    if (!response.ok) {
      throw new Error("Failed to download file");
    }

    // Create a blob from the response
    const blob = await response.blob();

    // Create a temporary link element and trigger the download
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename; // Set the filename for the downloaded file
    link.click();

    console.log(`File ${filename} downloaded successfully.`);
  } catch (error) {
    console.error("Error downloading the file:", error);
  }
};
